import { motion, Variants } from 'framer-motion';
import React from 'react';
import { ProductInfo } from './ProductInfo';

interface Props {
  name: string;
  product: StripeProductWithPrices;
  isCheckoutLoading?: boolean;
  interval?: PaymentInterval;
  isPricingPage?: boolean;
  onSetInterval?: (interval: PaymentInterval) => void;
  onSelect?: (priceId: string) => void;
}

export const ProductItem: React.FC<Props> = ({
  product,
  name,
  interval,
  isCheckoutLoading,
  isPricingPage,
  onSelect,
  onSetInterval,
}) => {
  const item: Variants = {
    hidden: {
      opacity: 0,
      y: 10,
    },
    show: {
      opacity: [0, 1],
      y: [10, 0],
      transition: {
        ease: 'easeInOut',
      },
    },
  };

  return (
    <motion.article
      variants={item}
      data-cy={`subscription-product-card-${name}`}
      className="subscription-product__card"
    >
      <ProductInfo
        product={product}
        onSelect={onSelect}
        isCheckoutLoading={isCheckoutLoading}
        interval={interval}
        onSetInterval={onSetInterval}
        isPricingPage={isPricingPage}
      />
    </motion.article>
  );
};
