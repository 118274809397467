import { DropResult } from 'react-beautiful-dnd';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { isProposalSegmentArray } from '../type-guards';

export function useProposalSegments() {
  const { control, setValue, getValues } =
    useFormContext<PublishStatusAware<Proposal>>();
  const { fields, move, remove, insert } = useFieldArray<
    PublishStatusAware<Proposal>
  >({
    control,
    name: 'segments',
  });

  function reorderSegments(dropResult: DropResult) {
    if (dropResult.destination) {
      move(dropResult.source.index, dropResult.destination?.index);
    }
  }

  function moveItem(index: number, direction: 'up' | 'down') {
    if (direction === 'up' && index === 0) return;
    if (direction === 'down' && index === getValues().segments.length - 1) {
      return;
    }

    const newIndex = direction === 'up' ? index - 1 : index + 1;
    const reorderedItems = Array.from(getValues().segments);
    const [movedItem] = reorderedItems.splice(index, 1);
    reorderedItems.splice(newIndex, 0, movedItem);

    if (isProposalSegmentArray(reorderedItems)) {
      setValue('segments', reorderedItems, { shouldDirty: true });
    }
  }

  function deleteSegment(index: number) {
    remove(index);
  }

  function insertEmptyHeading(index: number) {
    insert(index, {
      type: 'heading',
      title: '',
      subtitle: '',
    });
  }

  function insertEmptyService(index: number) {
    insert(index, {
      type: 'service',
      discount: 0,
      pricePerUnit: 0,
      quantity: 1,
      service: null,
      unit: null,
      content: '[{"type":"paragraph","children":[{"text":""}]}]',
    });
  }

  function insertEmptyText(index: number) {
    insert(index, {
      type: 'text',
      content: '[{"type":"paragraph","children":[{"text":""}]}]',
    });
  }

  function insertFile(index: number) {
    insert(index, {
      type: 'file',
      files: [],
    });
  }

  function insertMedia(index: number) {
    insert(index, {
      type: 'media',
      files: [],
    });
  }

  function addSegment(type: ProposalSegment['type'], index: number) {
    if (type === 'heading') {
      insertEmptyHeading(index);
      return;
    }

    if (type === 'service') {
      insertEmptyService(index);
      return;
    }

    if (type === 'file') {
      insertFile(index);
      return;
    }

    if (type === 'media') {
      insertMedia(index);
      return;
    }

    insertEmptyText(index);
  }

  return {
    segments: fields,
    reorderSegments,
    addSegment,
    deleteSegment,
    moveItem,
  };
}
