import { useFunction } from 'modules/firebase';

export function useStripeCheckoutCallables() {
  const createBillingPortalSession = useFunction<
    CreateBillingPortalSession,
    StripeBillingPortalSession
  >('createBillingPortalSession');

  const callables = {
    createBillingPortalSession,
  };

  const functionsInitialised = Object.values(callables).every((value) =>
    Boolean(value),
  );

  return {
    ...callables,
    functionsInitialised,
  };
}
