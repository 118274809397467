import { motion } from 'framer-motion';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useMobile, useSearchResultsListNavigation } from 'shared/hooks';
import { ClientSearchResultsListItem } from './ClientSearchResultsListItem';
import { ProposalSearchResultsListItem } from './ProposalSearchResultsListItem';

interface Props {
  proposals?: Proposal[];
  clients?: Client[];
  onSetShowResults: (showResults: boolean) => void;
}

export const SearchResultsList: React.FC<Props> = ({
  proposals,
  clients,
  onSetShowResults,
}) => {
  const isMobile = useMobile();
  const {
    clientsRef,
    proposalsRef,
    cursor,
    focusedList,
    handleMouseEnter,
    handleMouseLeave,
  } = useSearchResultsListNavigation({
    clients,
    proposals,
  });

  return (
    <motion.aside
      style={{
        opacity: 0,
        y: -20,
      }}
      animate={{ opacity: 1, y: 0 }}
      transition={{
        duration: 0.2,
        delay: isMobile ? 0 : 0.3,
        type: 'intertia',
      }}
      className="search__results"
      data-cy="search-results-container"
    >
      {Boolean(clients?.length) && (
        <ul
          ref={clientsRef}
          className="search__results__wrapper search__results__wrapper--clients"
        >
          <span className="t-label t-text-2 search__results__label">
            <FormattedMessage id="search.search-results.clients" />
          </span>
          {clients?.map((client, index) => (
            <React.Fragment key={client.id}>
              <ClientSearchResultsListItem
                client={client}
                onSetShowResults={onSetShowResults}
                isActive={cursor === index && focusedList === 'clients'}
                onMouseEnter={() => handleMouseEnter(client)}
                onMouseLeave={handleMouseLeave}
              />
              {index !== clients.length - 1 && (
                <div className="custom-tags-search__results__divider" />
              )}
            </React.Fragment>
          ))}
        </ul>
      )}

      {Boolean(proposals?.length) && (
        <>
          <ul ref={proposalsRef} className="search__results__wrapper">
            <span className="t-label t-text-2 search__results__label">
              <FormattedMessage id="search.search-results.proposals" />
            </span>
            {proposals?.map((proposal, i) => (
              <React.Fragment key={proposal.id}>
                <ProposalSearchResultsListItem
                  proposal={proposal}
                  index={i}
                  isActive={cursor === i && focusedList === 'proposals'}
                  onMouseEnter={() => handleMouseEnter(proposal)}
                  onMouseLeave={handleMouseLeave}
                />
                {i !== proposals.length - 1 && (
                  <div className="custom-tags-search__results__divider" />
                )}
              </React.Fragment>
            ))}
          </ul>
        </>
      )}
    </motion.aside>
  );
};
