import closeIcon from 'assets/icons/icon-close.svg';
import retryIcon from 'assets/icons/icon-retry-16.svg';
import warningIcon from 'assets/icons/icon-warning-file-16.svg';
import { useMediaAttachmentList } from 'modules/proposals';
import React, { memo } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FormattedMessage } from 'react-intl';
import { Button } from 'shared/components';
import { isImageFile, isPDFFile, isVideoFile } from 'shared/utils';
import { MediaListItemImage } from './MediaListItemImage';
import { MediaListItemPDF } from './MediaListItemPDF';
import { MediaListItemVideo } from './MediaListItemVideo';

interface Props {
  proposalFiles: ProposalFile[];
  index: number;
  fileErrors?: Record<string, string>;
  onRemoveFile: (file: ProposalFile) => void;
  onRemoveFileError: (fileName: string) => void;
}

export const MediaAttachmentInputList: React.FC<Props> = memo(
  ({ proposalFiles, index, fileErrors, onRemoveFile, onRemoveFileError }) => {
    const { handleOnDragEnd, moveItem } = useMediaAttachmentList(
      proposalFiles,
      index,
    );

    return (
      <>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="files">
            {(provided) => (
              <ul
                className="file-input__uploaded__list"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {proposalFiles.map((file, index) => (
                  <Draggable
                    key={file.name}
                    draggableId={file.name}
                    index={index}
                    disableInteractiveElementBlocking // Source: https://stackoverflow.com/questions/62624441/react-beautiful-dnd-with-html-video-not-bubbling-events
                  >
                    {(provided) => (
                      <li
                        className="media-input__uploaded__list-item__container"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {isImageFile(file.name) && (
                          <MediaListItemImage
                            file={file}
                            index={index}
                            proposalFilesLength={proposalFiles.length}
                            onRemoveFile={onRemoveFile}
                            moveItem={moveItem}
                          />
                        )}
                        {isVideoFile(file.name) && (
                          <MediaListItemVideo
                            file={file}
                            index={index}
                            proposalFilesLength={proposalFiles.length}
                            onRemoveFile={onRemoveFile}
                            moveItem={moveItem}
                          />
                        )}
                        {isPDFFile(file.name) && (
                          <MediaListItemPDF
                            fileUrl={file.url}
                            file={file}
                            index={index}
                            proposalFilesLength={proposalFiles.length}
                            onRemoveFile={onRemoveFile}
                            moveItem={moveItem}
                          />
                        )}
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>

        {fileErrors &&
          Object.keys(fileErrors).map((fileName, index) => (
            <div
              key={index}
              className="file-input__uploaded__list-item__container file-input__uploaded__list-item__container--error s-top--med"
            >
              <div className="file-input__uploaded__list-item__information__container t-ellipsis">
                <img
                  className="file-input__uploadedmessage__img"
                  src={warningIcon}
                  alt="WarningIcon"
                />
                <div className="f f--col t-ellipsis">
                  <span className="t-text-1 text--xs__responsive">
                    {fileName}
                  </span>
                  <span className="t-label--error text--xxs__responsive t-ellipsis">
                    {fileErrors[fileName]}
                  </span>
                </div>
              </div>

              {fileErrors[fileName].includes('Upload failed') && (
                <>
                  <Button
                    type="button"
                    size="med"
                    style="primary"
                    className="u-fit--content d--desktop--only"
                    btnSelector="upload-files-error-btn"
                  >
                    <img
                      className="file-input__uploadedmessage__img"
                      src={retryIcon}
                      alt="RetryIcon"
                    />
                    <span className="s-left--sml">
                      <FormattedMessage id="uploaders.file.error-cta" />
                    </span>
                  </Button>
                  <Button
                    type="button"
                    style="primary"
                    size="med"
                    className="u-fit--content d--mobile--only file-input__uploadedmessage__retry--btn"
                    btnSelector="upload-files-error-btn"
                  >
                    <img
                      className="file-input__uploadedmessage__img"
                      src={retryIcon}
                      alt="RetryIcon"
                    />
                  </Button>
                </>
              )}

              <Button
                className="file-input__uploadedmessage__close--btn"
                onClick={() => onRemoveFileError(fileName)}
              >
                <img src={closeIcon} alt="CloseIcon" />
              </Button>
            </div>
          ))}
      </>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.proposalFiles === nextProps.proposalFiles &&
      prevProps.fileErrors === nextProps.fileErrors
    );
  },
);
