import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal, ModalContent, ModalHeader } from 'shared/components';
import { useConversionRate } from 'shared/hooks';
import { useStripeProducts, useSubscriptionCheckout } from '../hooks';
import { ProductItem } from './ProductItem';
import { SubscriptionSelectModalMobile } from './SubscriptionSelectModalMobile';
import { SubscriptionSelectPlan } from './SubscriptionSelectPlan';

export const SubscriptionSelectModal: React.FC<ModalProps> = ({ onClose }) => {
  const { freeProduct, plusYearlyProduct, plusMonthlyProduct } =
    useStripeProducts();
  const { conversionRate, isDualPricingEnabled } = useConversionRate();

  const [interval, setInterval] = useState<PaymentInterval>('year');
  const [currentPlan, setCurrentPlan] = useState<SubscriptionTierAlias>('plus');

  const { loading, handleCheckout } = useSubscriptionCheckout();

  return (
    <Modal
      containerClassName="subscription-product__group__modal"
      isPropozePlusModal
      close={onClose}
    >
      <ModalHeader headerSelector="propoze-plus-modal-title">
        <div className="f f--col">
          <p className="text--xl__responsive">
            <FormattedMessage id="subscription-select-modal.heading" />
          </p>
          <SubscriptionSelectPlan
            view={currentPlan}
            toggleView={setCurrentPlan}
          />
        </div>
      </ModalHeader>
      <ModalContent
        className="subscription-product__group__content"
        contentSelector="propoze-plus-modal-content"
      >
        <SubscriptionSelectModalMobile
          currentPlan={currentPlan}
          interval={interval}
          onSetCurrentPlan={setCurrentPlan}
          onSetInterval={setInterval}
        />
        <div className="subscription-product__group">
          {freeProduct && (
            <ProductItem
              product={freeProduct}
              onSelect={handleCheckout}
              name={freeProduct.name}
            />
          )}
          {interval === 'year' && plusYearlyProduct && (
            <ProductItem
              key={plusYearlyProduct.id}
              product={plusYearlyProduct}
              onSelect={handleCheckout}
              name={plusYearlyProduct.name}
              interval={interval}
              onSetInterval={setInterval}
              isCheckoutLoading={loading}
            />
          )}
          {interval === 'month' && plusMonthlyProduct && (
            <ProductItem
              key={plusMonthlyProduct.id}
              product={plusMonthlyProduct}
              onSelect={handleCheckout}
              name={plusMonthlyProduct.name}
              interval={interval}
              onSetInterval={setInterval}
              isCheckoutLoading={loading}
            />
          )}
        </div>
        {isDualPricingEnabled && (
          <p className="subscription-product__conversion-description t-small t-text-3">
            {conversionRate}
          </p>
        )}
      </ModalContent>
    </Modal>
  );
};
