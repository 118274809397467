import { motion } from 'framer-motion';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CustomTagAddIcon } from './CustomTagAddIcon';

interface Props {
  inputValue: string;
  recentSearches?: string[] | null;
  handleAddCustomTag: (tag: string) => void;
}

export const CustomTagSelectResults: React.FC<Props> = ({
  inputValue,
  recentSearches,
  handleAddCustomTag,
}) => {
  const resultsExist = recentSearches && recentSearches?.length > 0;

  if (!inputValue.length && !resultsExist) return null;

  return (
    <motion.aside
      style={{
        opacity: 0,
        y: -20,
      }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: 0.3, type: 'intertia' }}
      className="custom-tags-search__results"
    >
      <ul className="custom-tags__results__wrapper">
        {resultsExist && (
          <>
            <span className="t-label t-text-2">
              <FormattedMessage id="inputs.custom-tags.recent" />
            </span>
            {recentSearches.map((search, index) => (
              <React.Fragment key={search}>
                <li
                  onClick={() => handleAddCustomTag(search)}
                  className="text--xxs__responsive t-text-1"
                >
                  {search}
                </li>
                {index !== recentSearches.length - 1 && (
                  <div className="custom-tags-search__results__divider" />
                )}
              </React.Fragment>
            ))}
          </>
        )}
        {inputValue?.length > 0 && (
          <div
            className="custom-tags__btn btn__icon__fill"
            onClick={() => handleAddCustomTag(inputValue)}
          >
            <CustomTagAddIcon />
            <p className="text--xxs__responsive">
              <FormattedMessage id="inputs.custom-tags.add-new-tag" />{' '}
              <span className="custom-tags__btn--tag">“{inputValue}”</span>
            </p>
          </div>
        )}
      </ul>
    </motion.aside>
  );
};
