import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'shared/components';

const INTERVALS: {
  name: string;
  interval: PaymentInterval;
  price: string;
}[] = [
  {
    name: 'Yearly',
    interval: 'year',
    price: '108 €',
  },
  {
    name: 'Monthly',
    interval: 'month',
    price: '10 €',
  },
];

interface Props {
  interval: PaymentInterval | undefined;
  setInterval: (interval: PaymentInterval) => void;
  disabled?: boolean;
}

export const IntervalSelector: React.FC<Props> = ({
  interval,
  disabled,
  setInterval,
}) => {
  function handleInterval(item: PaymentInterval) {
    if (item !== interval) setInterval(item);
  }

  return (
    <div className="f subscription-interval__selector">
      {INTERVALS.map((item) => (
        <Button
          key={item.interval}
          disabled={disabled}
          btnSelector={`choose-interval-${item.interval}-btn`}
          onClick={() => handleInterval(item.interval)}
          className={`t-small payment-interval payment-interval-${
            item.interval
          } ${
            interval === item.interval
              ? 't-primary payment-interval-selector__active'
              : 't-text-2 payment-interval-selector__inactive'
          }`}
        >
          <span className="text--xsm">
            <FormattedMessage
              id={`payment-interval-selector.${item.interval}`}
            />
          </span>
          <h2 className="f f--align-center t-bold">
            <strong className="text--lg">{item.price}</strong>

            {item.interval && (
              <span className="t-small s-left--sml" data-cy="interval-label">
                / {'  '}
                {item.interval}
              </span>
            )}
          </h2>
          <span className="payment-interval__vat">
            <FormattedMessage id="payment-interval-selector.vat-excluded" />
          </span>

          {item.interval === 'year' && (
            <span className="payment-interval__yearly__discount">
              <FormattedMessage id="payment-interval-selector.yearly-discount" />
            </span>
          )}
        </Button>
      ))}
    </div>
  );
};
