import { useProposalOverviewFilterOptions } from 'modules/proposals-overview';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useKeyPress, useListNavigation } from 'shared/hooks';

interface Props {
  onSetFilterOption?: (option: FilterOption | null) => void;
}

export const ProposalOverviewFilterSelect: React.FC<Props> = ({
  onSetFilterOption,
}) => {
  const enterPress = useKeyPress('Enter');
  const { filteredFilterOptions } = useProposalOverviewFilterOptions();

  const { cursor, setHovered } = useListNavigation<FilterOption>(
    filteredFilterOptions,
  );

  function handleSelect(option: FilterOption) {
    onSetFilterOption?.(option);
  }

  /**Enter press */
  useEffect(() => {
    if (enterPress) {
      handleSelect(filteredFilterOptions[cursor]);
    }
  }, [enterPress]);

  return (
    <ul className="proposal-overview__filter__options">
      {filteredFilterOptions.length > 0 ? (
        filteredFilterOptions.map((option, index) => (
          <React.Fragment key={option}>
            <li
              className={`proposal-overview__filter__option text--sm__responsive ${
                index === cursor
                  ? 'proposal-overview__filter__option--active'
                  : ''
              }`}
              onClick={() => onSetFilterOption?.(option)}
              data-cy={`proposal-overview-filter-${option}-btn`}
              onMouseEnter={() => setHovered(option)}
              onMouseLeave={() => setHovered(undefined)}
            >
              <FormattedMessage id={`proposals.overview.filter.${option}`} />
            </li>
            {index !== filteredFilterOptions.length - 1 && (
              <div className="proposal-overview__actions__divider" />
            )}
          </React.Fragment>
        ))
      ) : (
        <span className="proposal__overview__filter__option__error text--sm__responsive">
          <FormattedMessage id="proposals.overview.filter.no-results" />
        </span>
      )}
    </ul>
  );
};
