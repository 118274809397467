import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, SortCheckmarkIcon } from 'shared/components';

interface Props<T> {
  sortOption: T;
  unit?: SortUnit;
  onSetSortOption: (sortOption: T) => void;
  handleCloseDropdownToggle?: () => void;
  sortOptions: T[];
}

export const SortOptions = <T extends string>({
  sortOption,
  unit = 'overview',
  onSetSortOption,
  handleCloseDropdownToggle,
  sortOptions = ['newest', 'oldest', 'title', 'status'] as T[],
}: Props<T>) => {
  function isActive(currentSortOption: T) {
    return currentSortOption === sortOption;
  }

  function handleClick(sortStatus: T) {
    onSetSortOption(sortStatus);
    handleCloseDropdownToggle?.();
  }

  return (
    <>
      {sortOptions.map((option, index) => (
        <React.Fragment key={option}>
          <Button
            type="button"
            className={`btn actionbar__drawer__btn proposal-overview__sort__option ${
              isActive(option) && 'proposal__sort__item--selected'
            } btn__icon__stroke`}
            btnSelector={`proposal-sort-${option}`}
            onClick={() => handleClick(option)}
          >
            <FormattedMessage id={`proposals.${unit}.sort.${option}`} />
            {isActive(option) && <SortCheckmarkIcon />}
          </Button>
          {index !== sortOptions.length - 1 && (
            <div className="proposal-overview__actions__divider" />
          )}
        </React.Fragment>
      ))}
    </>
  );
};
