import { currencyOptions } from 'modules/proposals';
import React, { useCallback, useMemo } from 'react';
import {
  Controller,
  ControllerRenderProps,
  useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import Select, { SingleValue } from 'react-select';
import { FieldWrapper, getSelectStyles } from 'shared/components';
import { useMobile } from 'shared/hooks';

export const OnboardingDefaultCurrency: React.FC = () => {
  const { formatMessage } = useIntl();
  const isMobile = useMobile();

  const {
    control,
    formState: { errors },
    getValues,
  } = useFormContext<Settings>();

  const options = useMemo(
    () =>
      currencyOptions?.map((item) => ({
        value: item,
        label: item,
      })),
    [currencyOptions],
  );

  const handleCurrencyChange = useCallback(
    (field: ControllerRenderProps<Settings, 'defaultCurrency'>) =>
      (
        selectedOption: SingleValue<{
          value: string;
          label: string;
        }>,
      ) => {
        field.onChange(selectedOption?.value);
      },
    [],
  );

  function getValue(data?: string | null) {
    if (!data) return null;

    return {
      value: data,
      label: data,
    };
  }

  return (
    <div
      className="onboarding__default__currency"
      data-cy="default-currency-dropdown"
    >
      <FieldWrapper name="defaultCurrency" isRequired errors={errors}>
        <Controller
          name="defaultCurrency"
          control={control}
          rules={{
            required: formatMessage({
              id: 'dropdowns.currency.required',
            }),
          }}
          defaultValue={getValues('defaultCurrency') || null}
          render={({ field }) => (
            <Select
              {...field}
              options={options}
              onChange={handleCurrencyChange(field)}
              value={getValue(field.value)}
              styles={getSelectStyles<string>(
                Boolean(errors.defaultCurrency),
                124,
              )}
              id="currency__styled__select"
              className="select__currency--input"
              placeholder="Select currency"
              isSearchable={!isMobile}
            />
          )}
        />
      </FieldWrapper>
    </div>
  );
};
