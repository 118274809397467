import { format } from 'date-fns';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { Button, LoadingSpinner } from 'shared/components';
import { getGracePeriodFormattedDate } from 'shared/utils';
import {
  usePricingSelectPlan,
  useStripeHelpers,
  useSubscriptionHelpers,
} from '../hooks';
import { subscriptionAtoms, subscriptionSelectors } from '../state';
import { CurrentPlanCardHeader } from './CurrentPlanCardHeader';
import { ProductFeatureList } from './ProductFeatureList';
import { ProductVatExcludedTooltip } from './ProductVatExcludedTooltip';

interface Props {
  price: StripePrice;
  product: StripeProductWithPrices;
  active: Subscription['active'];
  end: Subscription['end'];
  onSetModalVisible: (visible: boolean) => void;
}

export const CurrentPlanCard: React.FC<Props> = ({
  product,
  price,
  active,
  end,
  onSetModalVisible,
}) => {
  const [loading, setLoading] = useState(false);
  const isTierOne = useRecoilValue(subscriptionSelectors.isTierOne);

  const isCanceled = useRecoilValue(subscriptionAtoms.isCanceled);
  const discountPercentage = useRecoilValue(
    subscriptionAtoms.discountPercentage,
  );

  const { handleCheckout } = usePricingSelectPlan();
  const { isGracePeriod } = useSubscriptionHelpers();
  const {
    getProductFeatureListFromMetadata,
    getProductPriceStringByInterval,
    getPriceWithDiscount,
  } = useStripeHelpers();

  const features = getProductFeatureListFromMetadata(product.metadata);
  const formattedPrice = getProductPriceStringByInterval(
    price,
    price.recurring?.interval,
  );
  const priceWithDiscount = getPriceWithDiscount(
    formattedPrice?.price,
    discountPercentage,
  );

  function renderPlanCardExpiration(end: number) {
    if (isGracePeriod) return getGracePeriodFormattedDate(end);

    if (!isTierOne) return 'Forever';

    return format(end, 'MMM dd, yyyy');
  }

  async function handleSelectPlan() {
    if (isTierOne) {
      setLoading(true);
      await handleCheckout();
      setLoading(false);
      return;
    }

    onSetModalVisible(true);
  }

  return (
    <div className="subscription-settings-plan" data-cy="current-plan-card">
      <CurrentPlanCardHeader
        product={product}
        active={active}
        end={end}
        isGracePeriod={isGracePeriod}
      />

      {end && (
        <>
          <div className="f f--align-center">
            <div
              className="f f--col f--align-start u-width--half"
              data-cy="valid-until-label"
            >
              <span className="s-bottom--tny t-text-2 text--sm">
                {isCanceled ? (
                  <FormattedMessage id="current-plan-card.label.valid-until" />
                ) : (
                  <FormattedMessage id="current-plan-card.label.renewal-date" />
                )}
              </span>
              <p className="text--sm t-text-1 t-bold">
                {typeof end === 'number'
                  ? renderPlanCardExpiration(end)
                  : 'Lifetime'}
              </p>
            </div>
            {!discountPercentage ? (
              <div
                className="f f--col f--align-start u-width--half"
                data-cy="current-plan-price-label"
              >
                <span className="s-bottom--tny t-text-2 text--sm">
                  <FormattedMessage id="current-plan-card.price" />
                </span>
                <div className="f">
                  <p className="text--sm t-text-1 t-bold">
                    {end === 'permanent' ? 'AppSumo' : formattedPrice.price}
                  </p>
                  {isTierOne && end !== 'permanent' && (
                    <>
                      <span className="text--tny s-left--sml t-text-2 t-letter-1 f--align-self-end">
                        (
                        <FormattedMessage id="current-plan-card.label.vat-excluded" />
                        )
                      </span>
                      <ProductVatExcludedTooltip className="f--align-self-end" />
                    </>
                  )}
                </div>
              </div>
            ) : (
              <div className="f f--align-start u-width--half g-4">
                <div className="f f--col f--align-start">
                  <div className="f f--align-center g-4">
                    <span className="t-text-2 text--sm">
                      <FormattedMessage id="current-plan-card.price" />
                    </span>
                    <span className="t-primary t-bold text--tny">
                      -{discountPercentage}%{' '}
                    </span>
                  </div>
                  <div className="f f--align-center">
                    <div className="f f--align-start g-4 subscription-settings-plan__discount">
                      <p className="text--sm t-text-2 t-bold t-strike">
                        {formattedPrice.price}
                      </p>
                      <p className="text--sm t-text-1 t-bold">
                        {priceWithDiscount}
                      </p>
                    </div>
                    <div className="f">
                      <span className="text--tny s-left--sml t-text-2 t-letter-1 f--align-self-end">
                        (
                        <FormattedMessage id="current-plan-card.label.vat-excluded" />
                        )
                      </span>
                      <ProductVatExcludedTooltip className="f--align-self-end" />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="divider--verylight-5 s-top--med s-bottom--med" />
        </>
      )}

      <main data-cy="product-description-feature-list">
        <ProductFeatureList
          features={features}
          productId={product.id}
          className="list--2x2__responsive"
        />
        {!isTierOne && (
          <span
            className="subscription-settings-free__notice t-text-2"
            data-cy="free-notice-disclaimer"
          >
            <FormattedMessage id="subscription-select-modal.free-plan-list-notice-includes" />
            <strong className="t-bold text--xsm">
              <FormattedMessage id="subscription-select-modal.free-plan-list-notice-published-proposals" />
            </strong>
            <FormattedMessage id="subscription-select-modal.free-plan-list-notice-description" />
          </span>
        )}
      </main>

      {isGracePeriod && end !== 'permanent' && (
        <p className="subscription-settings-plan__grace-period text--sm s-top--med">
          <FormattedMessage
            id="current-plan-card.description.grace-period"
            values={{
              date: (
                <strong>
                  {typeof end === 'number'
                    ? getGracePeriodFormattedDate(end)
                    : 'Lifetime'}
                </strong>
              ),
            }}
          />
        </p>
      )}

      {end !== 'permanent' && (
        <footer className="subscription-settings-plan__footer s-top--med">
          {loading ? (
            <Button
              type="button"
              size="lrg"
              style="outline"
              disabled={loading}
              btnSelector="downgrade-proccesing-disabled"
            >
              <LoadingSpinner height={12} width={12} type="primary" />
              <p className="s-left--med">
                <FormattedMessage id="subscription-action-controller.action.downgrading" />
              </p>
            </Button>
          ) : (
            <Button
              type="button"
              size="lrg"
              style="primary"
              onClick={handleSelectPlan}
              btnSelector="manage-plan-btn"
            >
              <FormattedMessage
                id={
                  !isTierOne
                    ? 'propoze-plus-notice.free.action'
                    : 'current-plan-card.action.manage-plan'
                }
              />
            </Button>
          )}
        </footer>
      )}
    </div>
  );
};
