import React from 'react';
import { useStripeProducts, useSubscriptionCheckout } from '../hooks';
import { ProductItem } from './ProductItem';

interface Props {
  currentPlan: SubscriptionTierAlias;
  interval: PaymentInterval;
  onSetCurrentPlan: (newPlan: SubscriptionTierAlias) => void;
  onSetInterval: (interval: PaymentInterval) => void;
}

export const SubscriptionSelectModalMobile: React.FC<Props> = ({
  currentPlan,
  interval,
  onSetInterval,
}) => {
  const { freeProduct, plusYearlyProduct, plusMonthlyProduct } =
    useStripeProducts();

  const { loading, handleCheckout } = useSubscriptionCheckout();

  return (
    <>
      <div className="subscription-product__group__mobile">
        {currentPlan === 'free' && freeProduct && (
          <ProductItem
            product={freeProduct}
            onSelect={handleCheckout}
            name={freeProduct.name}
          />
        )}
        {currentPlan === 'plus' && interval === 'year' && plusYearlyProduct && (
          <ProductItem
            key={plusYearlyProduct.id}
            product={plusYearlyProduct}
            onSelect={handleCheckout}
            name={plusYearlyProduct.name}
            interval={interval}
            onSetInterval={onSetInterval}
            isCheckoutLoading={loading}
          />
        )}
        {currentPlan === 'plus' &&
          interval === 'month' &&
          plusMonthlyProduct && (
            <ProductItem
              key={plusMonthlyProduct.id}
              product={plusMonthlyProduct}
              onSelect={handleCheckout}
              name={plusMonthlyProduct.name}
              interval={interval}
              onSetInterval={onSetInterval}
              isCheckoutLoading={loading}
            />
          )}
      </div>
    </>
  );
};
