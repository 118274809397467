import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'shared/components';

interface Props {
  view: SubscriptionTierAlias;
  toggleView: (newView: SubscriptionTierAlias) => void;
}

export const SubscriptionSelectPlan: React.FC<Props> = ({
  view,
  toggleView,
}) => {
  function getItemClassName(type: SubscriptionTierAlias) {
    return view === type
      ? 'subscription-product__group__select__plan__item--active'
      : 'subscription-product__group__select__plan__item--inactive';
  }

  return (
    <div className="subscription-product__group__select__plan">
      <Button
        type="button"
        onClick={() => toggleView('free')}
        className={`text--xs subscription-product__group__select__plan__item ${getItemClassName(
          'free',
        )}`}
      >
        <span>
          <FormattedMessage id="subscription-select-modal.select-plan-free" />
        </span>
      </Button>
      <Button
        type="button"
        onClick={() => toggleView('plus')}
        className={`text--xs subscription-product__group__select__plan__item ${getItemClassName(
          'plus',
        )}`}
      >
        <span>
          <FormattedMessage id="subscription-select-modal.select-plan-plus" />
        </span>
      </Button>
    </div>
  );
};
