import logoImage from 'assets/images/logo.svg';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'shared/components';
import { useMobile } from 'shared/hooks';

const backgroundFadeIn = (isMobile: boolean) => {
  return {
    hidden: {
      opacity: 0,
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
    visible: {
      opacity: 1,
      backgroundColor: isMobile ? '#ffffff' : '#8039ff',
    },
    exit: {
      opacity: 0,
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
  };
};

const transition = (isMobile: boolean) => {
  return {
    duration: isMobile ? 0 : 0.3,
    ease: 'easeOut',
  };
};

interface Props {
  isCreatingSubscription: boolean;
  onSetIsOnboardingLastStep: (value: boolean) => void;
}

export const OnboardingSuccessStep: React.FC<Props> = ({
  isCreatingSubscription,
  onSetIsOnboardingLastStep,
}) => {
  const isMobile = useMobile();

  return (
    <AnimatePresence>
      <motion.div
        variants={backgroundFadeIn(isMobile)}
        initial="hidden"
        animate="visible"
        exit="exit"
        transition={transition(isMobile)}
        className="onboarding__finish__container"
      >
        <motion.div
          initial={{ opacity: 0, y: 8 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0.3 }}
          exit={{ opacity: 0 }}
          key="onboarding-finish"
          className="onboarding__finish__main"
        >
          <img src={logoImage} alt="LogoImage" />
          <div className="f f--col s-top--med">
            <h1
              className="auth__header--message t-bold t-text-1 t-center"
              data-cy="welcome-message"
            >
              <FormattedMessage id="onboarding.stepper.step-4.title" />
            </h1>
            <span className="text--sm t-text-1 t-center u-maxwidth--432">
              <FormattedMessage
                id={
                  isCreatingSubscription
                    ? 'onboarding.stepper.step-4.loading.description'
                    : 'onboarding.stepper.step-4.done.description'
                }
              />
            </span>
          </div>
          {isCreatingSubscription ? (
            <div className="f f--col s-top--lrg">
              <div className="f f--center">
                <div className="loader"></div>
              </div>

              <span className="text--xsm t-text-3 t-center u-maxwidth--432 s-top--med">
                <FormattedMessage id="onboarding.stepper.step-4.loading.description2" />
              </span>
            </div>
          ) : (
            <Button
              size="lrg"
              style="primary"
              onClick={() => onSetIsOnboardingLastStep(false)}
              className="s-top--lrg"
              btnSelector="finish-onboarding-btn"
            >
              <FormattedMessage id="buttons.go-to-dashboard" />
            </Button>
          )}
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};
