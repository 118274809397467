import { ManageSidebar } from 'modules/dashboard';
import { StripePlansController } from 'modules/subscription';
import React, { PropsWithChildren } from 'react';
import { DashboardSidebarFooter, Header, Main } from 'shared/components';
import { SettingsIconBack } from '../settings-icon-back';

interface Props extends PropsWithChildren {
  showNotice?: boolean;
  isManageSection?: boolean;
}

export const SettingsLayout: React.FC<Props> = ({
  children,
  showNotice = true,
  isManageSection = false,
}) => {
  return (
    <>
      <div className="dashboard__container">
        <div className="dashboard__sidebar">
          <div className="dashboard__sidebar__content">
            <ManageSidebar showNotice={showNotice} />
          </div>
          <DashboardSidebarFooter className="dashboard__sidebar__footer__sticky" />
        </div>
        <div
          className="dashboard__content"
          data-cy="settings-dashboard-content"
        >
          <Header isManageSection={isManageSection} />
          <Main settingsBackComponent={SettingsIconBack}>{children}</Main>
        </div>
        <StripePlansController />
        <div id="global-modal" />
      </div>
    </>
  );
};
