import iconEmailVerified from 'assets/icons/icon-success-gray-12.svg';
import iconEmailNotVerified from 'assets/icons/icon-warning-12.svg';
import logoPropoze from 'assets/images/logo-small-grey.svg';
import { AnimatePresence, motion } from 'framer-motion';
import { Link } from 'gatsby';
import {
  authenticationSelector,
  useAuthentication,
} from 'modules/authentication';
import {
  authorizationSelector,
  useOrganizationIdSelector,
} from 'modules/authorization';
import { mobileNavDropdownVisibleSelector } from 'modules/dashboard';
import { FeatureFlagWrapper } from 'modules/feature-flags';
import { settingsSelector } from 'modules/settings';
import { GracePeriodNotice, PropozePlusNotice } from 'modules/subscription';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useSupportMail } from 'shared/hooks';
import { Button } from '../button';

export const MobileNavDropdownContent: React.FC = () => {
  const { formatMessage } = useIntl();
  const supportMail = useSupportMail();
  const { logout } = useAuthentication();
  const organizationId = useOrganizationIdSelector();

  const { roles, adminRole } = useSelector(authorizationSelector.getState);
  const { user } = useSelector(authenticationSelector.getState);
  const isTwitterLogin = useSelector(authenticationSelector.isTwitterLogin);
  const { config } = useSelector(settingsSelector.getState);

  const mobileNavdropdownVisible = useRecoilValue(
    mobileNavDropdownVisibleSelector,
  );
  const setMobileNavdropdownVisible = useSetRecoilState(
    mobileNavDropdownVisibleSelector,
  );
  const handleOpenDropdownTogle = () => {
    setMobileNavdropdownVisible(!mobileNavdropdownVisible);
  };

  const currentOrganizationRole = roles?.find(
    (item) => item.organizationId === organizationId,
  );

  const organizationName =
    currentOrganizationRole?.organizationName || config?.name;

  return (
    <AnimatePresence>
      <motion.section
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: 50, opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div className="companyselector-mobile__dropdown">
          {roles?.map((item) => (
            <div className="companyselector__company" key={item.organizationId}>
              <div className="companyselector__company__name">
                <span className="t-bold text--xs">{organizationName}</span>
                {user?.emailVerified || isTwitterLogin ? (
                  <img
                    src={iconEmailVerified}
                    className="companyselector__company__checkbox"
                    alt="Email verified icon"
                  />
                ) : (
                  <img
                    src={iconEmailNotVerified}
                    className="companyselector__company__checkbox"
                    alt="Email not verified icon"
                  />
                )}
              </div>

              <span
                className="companyselector__company__email text--sm t-text-2 t-ellipsis f--one companyselector__company__link"
                data-cy="dropdown-companyselector-email"
              >
                {user?.email}
              </span>
            </div>
          ))}
          <div className="companyselector-mobile__dropdown__notice">
            <PropozePlusNotice />
            <GracePeriodNotice />
          </div>
          <ul className="companyselector__buttons">
            {adminRole === 'superAdmin' && (
              <>
                <Link
                  onClick={handleOpenDropdownTogle}
                  className="dashboard__sidebar__link text--sm"
                  activeClassName="is-active"
                  to={formatMessage({ id: 'routes.admin.overview' })}
                  data-cy="admin-dashboard-sidebar-summary"
                >
                  <FormattedMessage id="admin.summary.title" />
                </Link>
                <Link
                  onClick={handleOpenDropdownTogle}
                  className="dashboard__sidebar__link text--sm"
                  activeClassName="is-active"
                  to={formatMessage({ id: 'routes.admin.users' })}
                  data-cy="admin-dashboard-sidebar-users"
                >
                  <FormattedMessage id="admin.users.title" />
                </Link>
                <Link
                  onClick={handleOpenDropdownTogle}
                  className="dashboard__sidebar__link text--sm"
                  activeClassName="is-active"
                  to={formatMessage({ id: 'routes.admin.proposals' })}
                  data-cy="admin-dashboard-sidebar-proposals"
                >
                  <FormattedMessage id="admin.proposals.title" />
                </Link>
                <div className="divider--verylight-5 s-top--sml s-bottom--sml" />
              </>
            )}
            <Link
              activeClassName="is-active"
              to={formatMessage({ id: 'routes.clients' })}
              onClick={handleOpenDropdownTogle}
              className="dashboard__sidebar__link text--sm"
              data-cy="dashboard-sidebar-client"
            >
              <FormattedMessage id="clients.title" />
            </Link>
            <Link
              activeClassName="is-active"
              onClick={handleOpenDropdownTogle}
              to={formatMessage({ id: 'routes.services' })}
              className="dashboard__sidebar__link text--sm"
              data-cy="dashboard-sidebar-service"
            >
              <FormattedMessage id="services.title" />
            </Link>
            <Link
              activeClassName="is-active"
              onClick={handleOpenDropdownTogle}
              to={formatMessage({ id: 'routes.units' })}
              className="dashboard__sidebar__link text--sm"
              data-cy="dashboard-sidebar-units"
            >
              <FormattedMessage id="units.title" />
            </Link>
            <div className="divider--verylight-5 s-top--sml s-bottom--sml" />
            <Link
              onClick={handleOpenDropdownTogle}
              to={formatMessage({ id: 'routes.company-info' })}
              activeClassName="is-active"
              className="dashboard__sidebar__link text--sm"
              data-cy="sidebar-general-info-settings"
            >
              <FormattedMessage id="settings.general-information.title" />
            </Link>
            <Link
              onClick={handleOpenDropdownTogle}
              to={formatMessage({ id: 'routes.address' })}
              activeClassName="is-active"
              className="dashboard__sidebar__link text--sm"
              data-cy="sidebar-address-settings"
            >
              <FormattedMessage id="settings.sections.address" />
            </Link>
            <Link
              onClick={handleOpenDropdownTogle}
              to={formatMessage({ id: 'routes.tax-settings' })}
              activeClassName="is-active"
              className="dashboard__sidebar__link text--sm"
              data-cy="sidebar-tax-settings"
            >
              <FormattedMessage id="settings.tax-settings.title" />
            </Link>
            <div className="divider--verylight-5 s-top--sml s-bottom--sml" />
            <FeatureFlagWrapper flag="SUBSCRIPTION">
              <Link
                onClick={handleOpenDropdownTogle}
                to={formatMessage({ id: 'routes.subscription-settings' })}
                activeClassName="is-active"
                className="dashboard__sidebar__link text--sm"
                data-cy="sidebar-subscription-settings"
              >
                <FormattedMessage id="settings.subscription.sidebar" />
              </Link>
            </FeatureFlagWrapper>
            <Link
              onClick={handleOpenDropdownTogle}
              to="/dashboard/settings/account"
              activeClassName="is-active"
              className="dashboard__sidebar__link text--sm"
              data-cy="sidebar-account-settings"
            >
              <FormattedMessage id="settings.account.title" />
            </Link>
            <div className="divider--verylight-5 s-top--sml s-bottom--sml" />
            <a
              href={formatMessage({ id: 'routes.pricing' })}
              onClick={handleOpenDropdownTogle}
              style={{ display: 'flex', justifyContent: 'flex-start' }}
              className="dashboard__sidebar__link text--sm"
              data-cy="pricing-page-link"
              rel="noreferrer"
            >
              <FormattedMessage id="footer.pricing-plans" />
            </a>
            <a
              href={formatMessage({ id: 'links.faq' })}
              style={{ display: 'flex', justifyContent: 'flex-start' }}
              className="dashboard__sidebar__link text--sm"
              data-cy="release-notes-link"
              rel="noreferrer"
            >
              <FormattedMessage id="home.faq.title" />
            </a>
            <a
              href={supportMail}
              style={{ display: 'flex', justifyContent: 'flex-start' }}
              className="dashboard__sidebar__link text--sm"
              data-cy="sidebar-address-settings"
            >
              <FormattedMessage id="buttons.get-in-touch" />
            </a>
          </ul>
          <div className="divider--verylight-5 s-top--sml s-bottom--sml" />
          <Button
            onClick={logout}
            className="text--sm companyselector__buttons__signout"
            btnSelector="dropdown-companyselector-signout-btn"
          >
            <FormattedMessage id="buttons.sign-out" />
          </Button>

          <div className="sliding-menu__footer">
            <img src={logoPropoze} alt="Propoze Logo" />
            <span className="t-text-3 dashboard__sidebar__footer__version">
              Version: {process.env.GATSBY_APP_VERSION?.split('-')[0]}
            </span>
          </div>
        </div>
      </motion.section>
    </AnimatePresence>
  );
};
