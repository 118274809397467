import closeIcon from 'assets/icons/icon-close-12.svg';
import sparksIcon from 'assets/icons/icon-sparks-12-white.svg';
import { useOrganizationIdSelector } from 'modules/authorization';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'shared/components';

interface Props {
  onOpenPlusModal: (isModalVisible: true) => void;
}

export const EditorBanner: React.FC<Props> = ({ onOpenPlusModal }) => {
  const organizationId = useOrganizationIdSelector();

  const hideEditorPlusBannerOrganizations = JSON.parse(
    localStorage.getItem('hideEditorPlusBanner') || '[]',
  );
  const [isBannerClosed, setIsBannerClosed] = useState(
    hideEditorPlusBannerOrganizations.includes(organizationId),
  );

  function closeProposalBanner() {
    localStorage.setItem(
      'hideEditorPlusBanner',
      JSON.stringify([...hideEditorPlusBannerOrganizations, organizationId]),
    );
    setIsBannerClosed(true);
  }

  function handleOpenPlusModal() {
    onOpenPlusModal(true);
  }

  if (isBannerClosed) return null;

  return (
    <header className="proposal-banner" data-cy="propoze-plus-notice-banner">
      <div className="proposal-banner__icon" />
      <div className="f f--align-center">
        <p className="proposal-banner__description text--sm t-bold s-right--med">
          <FormattedMessage id="propoze-plus-notice.editor.banner" />
        </p>
        <Button
          type="button"
          size="med"
          style="primary"
          onClick={handleOpenPlusModal}
          btnSelector="propoze-plus-notice-banner-btn"
        >
          <img
            src={sparksIcon}
            alt="Sparks icon"
            className="btn__icon btn__icon__left"
          />
          <FormattedMessage id="propoze-plus-notice.editor.banner__btn" />
        </Button>
      </div>
      <img
        src={closeIcon}
        alt="CloseIcon"
        className="proposal-banner__icon"
        data-cy="propoze-plus-notice-banner-close-btn"
        onClick={closeProposalBanner}
      />
    </header>
  );
};
