import {
  GracePeriodWrapper,
  subscriptionAtoms,
  SubscriptionSelectModal,
  usePropozePlusModal,
} from 'modules/subscription';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { getGracePeriodFormattedDate } from 'shared/utils';

interface Props {
  onCloseSlidingMenu?: (menuOpen: boolean) => void;
}

export const GracePeriodNotice: React.FC<Props> = ({ onCloseSlidingMenu }) => {
  const end = useRecoilValue(subscriptionAtoms.end);

  const { isModalVisible, openModal, closeModal } =
    usePropozePlusModal(onCloseSlidingMenu);

  return (
    <GracePeriodWrapper tier="free" exact>
      <div className="grace-period-notice">
        <div
          className="grace-period-notice__wrapper"
          data-cy="grace-period-notice"
        >
          {end !== 'permanent' && (
            <p className="s-bottom--tny text--xsm">
              <FormattedMessage
                id="grace-period-notice.title"
                values={{
                  date: (
                    <strong>
                      {typeof end === 'number' &&
                        getGracePeriodFormattedDate(end)}
                    </strong>
                  ),
                }}
              />
            </p>
          )}
          <div>
            <span
              onClick={openModal}
              className="s-bottom--tny text--xsm t-primary t-bold"
            >
              <FormattedMessage
                id="grace-period-notice.description"
                values={{
                  value: (
                    <p className="text--xsm t-normal t-text-1">
                      <FormattedMessage id="grace-period-notice.description-value" />
                    </p>
                  ),
                }}
              />
            </span>
          </div>
          {isModalVisible && <SubscriptionSelectModal onClose={closeModal} />}
        </div>
      </div>
    </GracePeriodWrapper>
  );
};
