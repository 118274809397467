import sparksIcon from 'assets/icons/icon-sparks-duo-12.svg';
import { ProtectedAccessList, VisibilityController } from 'modules/proposals';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useRecoilValue } from 'recoil';
import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from 'shared/components';
import { useSubmitOnEnter } from 'shared/hooks';
import { subscriptionSelectors } from '../state';
import { ProposalResponseToggle } from './ProposalResponseToggle';
import { ProposalServicePricesVisibilityToggle } from './ProposalServicePricesVisibilityToggle';
import { VisibilitySelectorFreemiumModal } from './VisibilitySelectorFreemiumModal';

type ProposalSettingsModalTabs = 'accessControl' | 'preferences';

interface Props extends ModalProps {
  publishStatus: PublishStatus;
  visibility: 'public' | 'protected';
  confirmButtonDisabled: boolean;
  handleUpdateList: () => Promise<void>;
}

export const ProposalSettingsModal: React.FC<Props> = ({
  publishStatus,
  visibility,
  confirmButtonDisabled,
  handleUpdateList,
  onClose,
}) => {
  const submitButtonRef = useSubmitOnEnter<HTMLButtonElement>();
  const isTierOne = useRecoilValue(subscriptionSelectors.isTierOne);
  const [activeTab, setActiveTab] =
    useState<ProposalSettingsModalTabs>('preferences');

  function isActiveTab(tab: ProposalSettingsModalTabs) {
    return activeTab === tab;
  }

  function toggleTab(tab: ProposalSettingsModalTabs) {
    setActiveTab(tab);
  }

  return (
    <Modal
      isDashboardModal
      close={onClose}
      containerClassName="proposal-settings__modal__container"
    >
      <ModalHeader>
        <p className="text--xl__responsive">Proposal settings</p>
      </ModalHeader>
      <ModalContent className="proposal-settings__modal__content">
        <nav className="proposal-settings__modal__sidebar">
          <ul>
            <Button
              className={`proposal-settings__modal__sidebar__link ${
                isActiveTab('preferences') ? 'is-active' : ''
              }`}
              onClick={() => toggleTab('preferences')}
              data-cy="proposal-settings-preferences"
            >
              <span>Preferences</span>
            </Button>
            {publishStatus !== 'template' && (
              <Button
                className={`proposal-settings__modal__sidebar__link ${
                  isActiveTab('accessControl') ? 'is-active' : ''
                }`}
                onClick={() => toggleTab('accessControl')}
                data-cy="proposal-settings-access-control"
              >
                <div className="f g-8">
                  <FormattedMessage id="proposal-acceptance-controller.description" />
                  {!isTierOne && (
                    <img
                      src={sparksIcon}
                      alt="Sparks icon"
                      className="btn__icon s-right--sml"
                    />
                  )}
                </div>
              </Button>
            )}
          </ul>
        </nav>
        <main
          className={`proposal-settings__modal__main ${
            isActiveTab('accessControl') && !isTierOne && 'f--center'
          }`}
        >
          {isActiveTab('accessControl') && (
            <>
              {isTierOne ? (
                <>
                  {' '}
                  <VisibilityController
                    publishStatus={publishStatus}
                    visibility={visibility}
                  />
                  {visibility === 'protected' && (
                    <ProtectedAccessList
                      isVisible={visibility === 'protected'}
                    />
                  )}
                </>
              ) : (
                <VisibilitySelectorFreemiumModal onClose={onClose} />
              )}
            </>
          )}
          {isActiveTab('preferences') && (
            <>
              <ProposalServicePricesVisibilityToggle />
              <div className="divider" />
              <ProposalResponseToggle />
            </>
          )}
        </main>
      </ModalContent>
      <ModalFooter className="m-left-auto--desktop">
        <div className="btn-group btn-group--simple">
          <Button onClick={onClose} type="button" size="lrg" style="ghost">
            <FormattedMessage id="buttons.cancel" />
          </Button>
          {isActiveTab('accessControl') && isTierOne && (
            <Button
              value="Save"
              size="lrg"
              type="submit"
              style="primary"
              className="u--width--full--mobile"
              btnSelector="company-settings-save-btn"
              disabled={confirmButtonDisabled}
              onClick={handleUpdateList}
              ref={submitButtonRef}
            >
              <FormattedMessage id="buttons.confirm" />
            </Button>
          )}
        </div>
      </ModalFooter>
    </Modal>
  );
};
