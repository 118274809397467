import { useFunction } from 'modules/firebase';

export function useStripeSubscriptionCallables() {
  const createSubscription =
    useFunction<SubscribeUserData>('createSubscription');

  const callables = {
    createSubscription,
  };

  const functionsInitialised = Object.values(callables).every((value) =>
    Boolean(value),
  );

  return {
    ...callables,
    functionsInitialised,
  };
}
