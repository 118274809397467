import { proposalsAtoms } from 'modules/proposals';
import {
  selectorFamily,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import { useFuseObjectSearch } from 'shared/hooks';
import { applySort } from 'shared/utils';
import { applyFilters } from '../utils';
import { overviewAtoms } from './atoms';

/**Global search by query selector */
type ProposalsQueryParams = {
  query: string;
  searchByClient?: boolean;
};

/**Overview proposals selector */
const filteredProposalsSelectorFamily = selectorFamily<
  Proposal[],
  ProposalsQueryParams
>({
  key: 'filteredProposalsSelector',
  get:
    ({ query }) =>
    ({ get }) => {
      const proposals = get(proposalsAtoms.proposals);
      const filtersOption = get(overviewAtoms.filtersOption);
      const sortOption = get(overviewAtoms.sortOption);

      const hasArchivedFilter = filtersOption?.some(
        (filter) => filter.value === 'archived',
      );
      let filteredProposals = useFuseObjectSearch(['name'], query, proposals);

      /** Hide archived proposals by default if user hasn't selected archived filter option. */
      if (!hasArchivedFilter) {
        filteredProposals = filteredProposals.filter(
          (proposal) => proposal.publishStatus !== 'archived',
        );
      }

      if (filtersOption.length) {
        filteredProposals = applyFilters(filtersOption, filteredProposals);
      }

      if (sortOption) {
        return applySort(sortOption, filteredProposals);
      }

      return filteredProposals;
    },
});
export const useFilteredProposalsSelector = (query: string) =>
  useRecoilValue(filteredProposalsSelectorFamily({ query }));

/**Overview sort option selector */
export const useOverviewSortState = () =>
  useRecoilState(overviewAtoms.sortOption);

/** Overview filters option selector */
export const useOverviewFiltersOptionSelector = () =>
  useRecoilValue(overviewAtoms.filtersOption);
export const useSetOverviewFiltersOption = () =>
  useSetRecoilState(overviewAtoms.filtersOption);
export const useOverviewFiltersState = () =>
  useRecoilState(overviewAtoms.filtersOption);

/**Overview upgrade plus modal open */
export const useOverviewUpgradePlusModalOpen = () =>
  useRecoilValue(overviewAtoms.isPlusUpgradeModalOpen);
export const useSetOverviewUpgradePlusModalOpen = () =>
  useSetRecoilState(overviewAtoms.isPlusUpgradeModalOpen);
