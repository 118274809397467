import { subscriptionSelectors } from 'modules/subscription';
import { useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useProposalsSelector } from '../state';
import { getStatisticsStartDate } from '../utils';

export function useProposalStatistics() {
  const proposals = useProposalsSelector();
  const [sortOption, setSortOption] =
    useState<ProposalStatisticsOption>('last7Days');
  const startDate = getStatisticsStartDate(sortOption);
  const isTierOne = useRecoilValue(subscriptionSelectors.isTierOne);

  const filteredProposals = useMemo(() => {
    const isAfterStartDate = (date: number) => new Date(date) >= startDate;

    return {
      created: proposals.filter((proposal) =>
        isAfterStartDate(proposal.creationDateTime),
      ),
      won: proposals.filter(
        (proposal) =>
          proposal.publishStatus === 'published' &&
          proposal.acceptanceStatus === 'accepted' &&
          proposal.acceptanceDateTime &&
          isAfterStartDate(proposal.acceptanceDateTime),
      ),
      lost: proposals.filter(
        (proposal) =>
          proposal.publishStatus === 'published' &&
          proposal.acceptanceStatus === 'rejected' &&
          proposal.acceptanceDateTime &&
          isAfterStartDate(proposal.acceptanceDateTime),
      ),
      sent: proposals.filter(
        (proposal) =>
          proposal.publishStatus === 'published' &&
          proposal.publishDateTime &&
          isAfterStartDate(proposal.publishDateTime),
      ),
      expired: proposals.filter(
        (proposal) =>
          proposal.publishStatus === 'published' &&
          proposal.acceptanceStatus === 'pending' &&
          proposal.validUntilDateTime &&
          proposal.validUntilDateTime < +new Date() &&
          isAfterStartDate(proposal.validUntilDateTime),
      ),
      viewed: proposals.filter(
        (proposal) =>
          proposal.lastViewedDateTime &&
          isAfterStartDate(proposal.lastViewedDateTime) &&
          (proposal.publishStatus === 'published' ||
            (proposal.publishStatus === 'draft' &&
              proposal.visibility === 'protected')),
      ),
    };
  }, [proposals, startDate]);

  const statistics: OrganizationAnalytics = useMemo(
    () => ({
      proposalsCreated: filteredProposals.created.length,
      proposalsWon: filteredProposals.won.length,
      proposalsLost: filteredProposals.lost.length,
      proposalsSent: filteredProposals.sent.length,
      proposalsExpired: isTierOne ? filteredProposals.expired.length : 0,
      proposalsViewed: filteredProposals.viewed.length,
    }),
    [filteredProposals],
  );

  return {
    ...statistics,
    sortOption,
    setSortOption,
  };
}
