import {
  FACELIFT_RELEASE_DATE,
  useHasOldProposalsSelector,
  useProposalsByStatusSelector,
} from 'modules/proposals';
import {
  FreeTierNotice,
  PublishedProposalsLimitTooltip,
  SubscriptionTierWrapper,
} from 'modules/subscription';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
  containerClassName?: string;
  className?: string;
}

export const PropozePlusNotice: React.FC<Props> = ({
  className,
  containerClassName,
}) => {
  const hasOldProposals = useHasOldProposalsSelector();
  const published = useProposalsByStatusSelector('published');

  const newPublished = useMemo(
    () =>
      published?.filter(
        (proposal) => proposal.creationDateTime > FACELIFT_RELEASE_DATE,
      ),
    [published],
  );
  const publishedLeft = useMemo(
    () => 3 - (newPublished?.length || 0),
    [newPublished],
  );

  return (
    <SubscriptionTierWrapper tier="free" exact>
      <div className={`propoze-plus-notice ${containerClassName || ''}`}>
        <div className={`f f--col g-8 ${className || ''}`}>
          <div className="f f--align-center g-8 f--justify-between">
            <p className="free__limit__description">
              {newPublished?.length >= 3 ? (
                <>
                  <FormattedMessage id="propoze-plus-notice.limit-excedeed.description1" />
                  <strong className="t-bold text--xsm">
                    <FormattedMessage
                      id="propoze-plus-notice.limit-excedeed.description2"
                      values={{
                        value: publishedLeft,
                      }}
                    />
                  </strong>
                  <FormattedMessage id="propoze-plus-notice.limit-excedeed.description3" />
                </>
              ) : (
                <>
                  <FormattedMessage id="propoze-plus-notice.limit.description1" />
                  <strong className="t-bold text--xsm">
                    <FormattedMessage
                      id="propoze-plus-notice.limit.description2"
                      values={{
                        value: publishedLeft,
                      }}
                    />
                  </strong>
                  <FormattedMessage id="propoze-plus-notice.limit.description3" />
                </>
              )}
            </p>
            {hasOldProposals && <PublishedProposalsLimitTooltip />}
          </div>
          <div className="free__limit__loading-bar-container">
            <div
              className="free__limit__loading-bar"
              style={{
                width: `${(100 * (3 - publishedLeft)) / 3}%`,
              }}
            ></div>
          </div>{' '}
        </div>
        <FreeTierNotice
          noticeActionId="propoze-plus-notice.sidebar.free.action"
          noticeDescriptionId="propoze-plus-notice.sidebar.free.description"
        />
      </div>
    </SubscriptionTierWrapper>
  );
};
