import trashIcon from 'assets/icons/icon-trash-100.svg';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, LoadingSpinner } from 'shared/components';
import { useBeforeUnload, useSubmitOnEnter } from 'shared/hooks';

interface Props {
  loading: boolean;
  onConfirmDeleteAccount: () => void;
}

export const DeleteAccountStatusModal: React.FC<Props> = ({
  loading,
  onConfirmDeleteAccount,
}) => {
  const submitButtonRef = useSubmitOnEnter<HTMLButtonElement>();
  useBeforeUnload(onConfirmDeleteAccount, true);

  return (
    <div className="account-delete-modal__status">
      <img
        src={trashIcon}
        alt="IconTrash"
        className="account-delete-modal__status__icon"
      />
      <h1 className="t-center text--xl__responsive s-top--lrg">
        <FormattedMessage id="account-settings.delete-status-modal.title" />
      </h1>
      <div className="t-text-3 text--xs__responsive">
        <p className="s-left--med">
          <FormattedMessage id="account-settings.delete-status-modal.description1" />
        </p>
        <p className="s-left--med">
          <FormattedMessage id="account-settings.delete-status-modal.description2" />
        </p>
      </div>
      {loading ? (
        <Button
          type="button"
          disabled={loading}
          size="lrg"
          style="primary"
          className="s-top--lrg u--width--full--mobile"
          btnSelector="processing-btn"
        >
          <LoadingSpinner height={12} width={12} type="negative" />
          <span className="s-left--med">
            <FormattedMessage id="subscription-action-controller.action.downgrading" />
          </span>
        </Button>
      ) : (
        <Button
          type="button"
          size="lrg"
          style="primary"
          className="s-top--lrg u--width--full--mobile"
          onClick={onConfirmDeleteAccount}
          ref={submitButtonRef}
          btnSelector="close-modal-btn"
        >
          <FormattedMessage id="account-settings.delete-status-modal.action.confirm" />
        </Button>
      )}
    </div>
  );
};
