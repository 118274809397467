import { useOrganizationIdSelector } from 'modules/authorization';
import { toast } from 'sonner';
import { useStripeSubscriptionCallables } from './useStripeSubscriptionCallables';

/** Stripe subscription related functions and helpers.
 * If using anything from useStripeCustomerCallables, @param functionsInitialised **MUST** be set,
 * otherwise you risk the callable function not being instantiated */

export function useStripeSubscription() {
  const organizationId = useOrganizationIdSelector();

  const { functionsInitialised, createSubscription } =
    useStripeSubscriptionCallables();

  async function createStripeSubscription(priceId: string, customerId: string) {
    if (!createSubscription) return;

    try {
      const { data } = await createSubscription({
        priceId,
        customerId,
        organizationId,
      });

      if (!data.success) throw new Error();
    } catch (error) {
      console.error(error);
      toast.error(`Subscribing user failed. Try again later.`);
    }
  }

  return {
    createStripeSubscription,
    functionsInitialised,
  };
}
