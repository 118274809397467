import { useProposalOverviewFilterOptions } from 'modules/proposals-overview';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useKeyPress, useListNavigation, useMobile } from 'shared/hooks';
import { ProposalOverviewFilterOption } from '../ProposalOverviewFilterOption';

interface Props {
  clients?: Client[];
}

export const ProposalOverviewClientOptions: React.FC<Props> = ({ clients }) => {
  const isMobile = useMobile();
  const enterPress = useKeyPress('Enter');
  const { handleFilterChange } = useProposalOverviewFilterOptions();
  const { ref, cursor, setHovered } = useListNavigation<Client | undefined>(
    clients,
  );

  /**Enter press */
  useEffect(() => {
    if (clients?.length && enterPress) {
      handleFilterChange(
        'client',
        clients[cursor]?.id || clients[cursor]?.name,
      );
    }
  }, [enterPress]);

  return (
    <ul ref={ref} className="proposal-overview__filter__options">
      {clients?.length ? (
        <>
          {clients?.map((client, index) => (
            <React.Fragment key={client.id}>
              <ProposalOverviewFilterOption
                type="client"
                name={client?.id || client?.name}
                label={client.name}
                isActive={index === cursor}
                handleFilterChange={handleFilterChange}
                onMouseEnter={() => setHovered(client)}
                onMouseLeave={() => setHovered(undefined)}
              />
              {index !== clients.length - 1 && isMobile && (
                <div className="proposal-overview__actions__divider" />
              )}
            </React.Fragment>
          ))}
        </>
      ) : (
        <span className="proposal__overview__filter__option__error text--sm">
          <FormattedMessage id="proposals.overview.filter.no-results" />
        </span>
      )}
    </ul>
  );
};
