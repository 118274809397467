import clearFiltersIcon from 'assets/icons/icon-reset-12.svg';
import { FeatureFlagWrapper } from 'modules/feature-flags';
import {
  ProposalOverviewFilter,
  ProposalOverviewList,
  ProposalOverviewMobileFilter,
  useOverviewSortState,
  useProposalOverview,
  useProposalOverviewFilterList,
} from 'modules/proposals-overview';

import { SubscriptionTierWrapper } from 'modules/subscription';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Sort } from 'shared/components';

export const ProposalOverviewSection: React.FC = () => {
  const [filterOption, setFilterOption] = useState<FilterOption | null>(null);
  const [sortOption, setSortOption] = useOverviewSortState();
  const { filteredProposals, handleRemoveFilter } = useProposalOverview();
  const { filters, clearAllFilters } = useProposalOverviewFilterList();

  return (
    <>
      <header
        className="dashboard__section__header__title text--xs t-bold"
        data-cy="dashboard-overview-title"
      >
        <FormattedMessage id="proposals.overview.title" />
      </header>
      <div className="dashboard__section__mobile__actions">
        <header className="text--xs t-bold" data-cy="dashboard-overview-title">
          <FormattedMessage
            id="proposals.overview.mobile-title"
            values={{
              length: filteredProposals.length,
            }}
          />
        </header>
        <div className="f f--align-center">
          <ProposalOverviewMobileFilter
            filterOption={filterOption}
            onSetFilterOption={setFilterOption}
            handleRemoveFilter={handleRemoveFilter}
          />
          <Sort sortOption={sortOption} onSetSortOption={setSortOption} />
        </div>
      </div>
      <div className="proposal-overview-filter__list__container">
        <div className="proposal-overview-filter__list">
          <ProposalOverviewFilter
            filterOption="client"
            handleRemoveFilter={handleRemoveFilter}
          />
          <SubscriptionTierWrapper tier="plus">
            <FeatureFlagWrapper flag="CUSTOM-TAGS">
              <ProposalOverviewFilter
                filterOption="tags"
                handleRemoveFilter={handleRemoveFilter}
              />
            </FeatureFlagWrapper>
          </SubscriptionTierWrapper>
          <ProposalOverviewFilter
            filterOption="proposal-status"
            handleRemoveFilter={handleRemoveFilter}
          />
          <ProposalOverviewFilter
            filterOption="acceptance"
            handleRemoveFilter={handleRemoveFilter}
          />
          <ProposalOverviewFilter
            filterOption="validity"
            handleRemoveFilter={handleRemoveFilter}
          />
          <ProposalOverviewFilter
            filterOption="date"
            handleRemoveFilter={handleRemoveFilter}
          />
        </div>
        {Boolean(filters.length) && (
          <Button
            style="ghost"
            size="med"
            className="f overview__filter__clear__btn t-text-1 text--xxs__responsive"
            btnSelector="clear-filters-btn"
            onClick={clearAllFilters}
          >
            <img
              src={clearFiltersIcon}
              alt="Clear filters icon"
              className="btn__icon btn__icon__left"
            />
            <FormattedMessage id="buttons.clear-filters" />
          </Button>
        )}
      </div>
      {Boolean(filteredProposals?.length) && (
        <div className="dashboard__section__actions">
          <span className="t-text-2 text--sm">
            <FormattedMessage
              id="proposals.overview.length"
              values={{
                length: filteredProposals.length,
              }}
            />
          </span>
          <Sort sortOption={sortOption} onSetSortOption={setSortOption} />
        </div>
      )}
      <ProposalOverviewList
        proposals={filteredProposals}
        className="dashboard__section__list__container"
        errorClassName="dashboard__section__error__container"
      />
    </>
  );
};
