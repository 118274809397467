import { useOrganizationIdSelector } from 'modules/authorization';
import { useFirestoreDocument } from 'modules/firebase';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { toast } from 'sonner';
import { subscriptionSelectors } from '../state';
import { useStripeCustomer } from './customer';
import { useStripeSubscription } from './subscription';

export function useFreeSubscription() {
  const [isLoading, setIsLoading] = useState(false);

  const organizationId = useOrganizationIdSelector();
  const products = useRecoilValue(subscriptionSelectors.productsSelector);

  const freeProduct = products?.find(({ name }) => name === 'Free');

  const { formatMessage } = useIntl();
  const { createStripeCustomer } = useStripeCustomer();
  const { createStripeSubscription } = useStripeSubscription();
  const { set } = useFirestoreDocument<Subscription>(
    `${organizationId}/subscription`,
  );

  async function handleFreeSubscription() {
    try {
      setIsLoading(true);
      const priceId = freeProduct?.prices[0].id;
      const newCustomerId = await createStripeCustomer();

      if (!newCustomerId || !priceId) {
        throw new Error('No customer or price id');
      }

      await createStripeSubscription(priceId, newCustomerId);
    } catch {
      toast.error(
        formatMessage({
          id: 'free-subscription.subscribe.api.error',
        }),
      );
    } finally {
      set({ status: null });
      setIsLoading(false);
    }
  }

  return { isLoading, handleFreeSubscription };
}
