import { subscriptionSelectors } from 'modules/subscription';
import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import { toast } from 'sonner';
import { useStripePricesCallables } from './useStripePricesCallables';

/** Stripe prices functions and helpers.
 * If using anything from useStripeCallables, @param functionsInitialised **MUST** be set,
 * otherwise you risk the callable function not being instantiated */

export function useStripePrices() {
  const setProducts = useSetRecoilState(subscriptionSelectors.productsSelector);
  const { getProducts, functionsInitialised } = useStripePricesCallables();

  const getStripeProducts = useCallback(async () => {
    if (!getProducts) return;
    try {
      const { data } = await getProducts();
      if (!data.success) throw new Error();

      setProducts(data.payload);
    } catch (error) {
      console.error(error);
      toast.error('Failed to fetch available products. Try again later.');
    }
  }, [getProducts]);

  return {
    getStripeProducts,
    functionsInitialised,
  };
}
