import { useFormState } from 'react-hook-form';
import { useIntl } from 'react-intl';

export function useConfirmDuplicate() {
  const { formatMessage } = useIntl();
  const { dirtyFields } = useFormState<Proposal>();
  const isDirty = !!Object.keys(dirtyFields).length;

  const handleConfirmDuplicate = (e: React.MouseEvent) => {
    e.preventDefault();
    const shouldContinue = window.confirm(
      formatMessage({ id: 'buttons.confirm-navigation' }),
    );
    return shouldContinue;
  };

  return { isDirty, handleConfirmDuplicate };
}
