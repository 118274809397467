import {
  StorageError,
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
} from 'firebase/storage';
import { auth } from '../initFirebase';

export function useStorage() {
  const storage = getStorage();

  function handleError(error: StorageError) {
    return error;
  }

  function getCollectionReference() {
    return ref(storage, `${auth.currentUser?.uid}`);
  }

  function updateFileUrl<
    T extends {
      segments?: ProposalSegment[];
    },
  >(data: T, name: string, url: string) {
    if (data.segments) {
      for (const item of data.segments) {
        if (item.type !== 'file' && item.type !== 'media') continue;
        for (const file of item.files) {
          if (file.name === name) {
            file.url = url;
          }
        }
      }
    }
  }

  function updatePropUrl<T extends Record<string, any>>(
    data: T,
    prop: string,
    url: string,
  ) {
    if (data[prop]) {
      (data as Record<string, any>)[prop] = url;
    }

    if (!data[prop]) {
      updateFileUrl(data, prop, url);
    }

    return data;
  }

  async function addStorageItemAsync(file: File, path: string, name?: string) {
    let reference = ref(getCollectionReference(), path);

    if (name) reference = ref(getCollectionReference(), `${path}/${name}`);

    const snapshot = await uploadBytes(reference, file);
    const url = await getDownloadURL(snapshot.ref);
    return url;
  }

  async function addFromLocalBlobAsync(
    blobUrl: string,
    path: string,
    name: string,
  ) {
    const response = await fetch(blobUrl);
    const blob = await response.blob();
    if (!blob) return;
    let contentType = blob.type;
    // If the file is an SVG content type is image/svg+xml
    if (name.endsWith('.svg') || contentType === 'application/octet-stream') {
      contentType = 'image/svg+xml';
    }
    //If the file is a GIF content type is image/gif
    if (name.endsWith('.gif')) {
      contentType = 'image/gif';
    }
    const file = new File([blob], name, { type: contentType });
    const url = await addStorageItemAsync(file, path, name);
    return url;
  }

  async function removeStorageItemAsync(path: string) {
    return deleteObject(ref(storage, path)).catch(handleError);
  }

  function findUrlByName<T extends ProposalFile>(array: T[], name: string) {
    for (const item of array) {
      if (item.name === name) return item.url;
    }
    return null;
  }

  function getFileUrl<
    T extends {
      segments?: ProposalSegment[];
    },
  >(data: T, prop: string): string | null {
    if (!data.segments?.length) return null;
    const segmentsArray = data.segments.flatMap((obj) => {
      if (obj.type === 'file' || obj.type === 'media') {
        return obj.files;
      }
      return [];
    });
    return findUrlByName(segmentsArray, prop);
  }

  async function processUploadsAsync<
    T extends Record<string, any> & { segments?: ProposalSegment[] },
  >(
    documentName: string,
    refId: string,
    data: T,
    imageProps: string[],
  ): Promise<T> {
    const uploadPromises = imageProps.map(async (prop) => {
      const imageUrl: string | null = data[prop] || getFileUrl(data, prop);
      if (!imageUrl) return null;

      const alreadyUploaded = imageUrl.startsWith(
        'https://firebasestorage.googleapis.com',
      );

      if (alreadyUploaded) return null;

      const uploadUrl = await addFromLocalBlobAsync(
        imageUrl,
        `${documentName}/${refId}`,
        prop,
      );

      if (typeof uploadUrl === 'string') {
        updatePropUrl(data, prop, uploadUrl);
      }

      return uploadUrl;
    });

    await Promise.allSettled(uploadPromises).then((results) => {
      results.forEach((result, index) => {
        if (result.status === 'fulfilled' && typeof result.value === 'string') {
          const prop = imageProps[index];
          updatePropUrl(data, prop, result.value);
        }
      });
    });

    return data;
  }

  async function getDownloadLink(path: string) {
    const pathReference = ref(storage, path);
    const url = await getDownloadURL(pathReference);
    return url;
  }

  return {
    addStorageItemAsync,
    removeStorageItemAsync,
    processUploadsAsync,
    getDownloadLink,
  };
}
