import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { subscriptionAtoms, subscriptionSelectors } from '../state';

interface SubscriptionProductProps {
  priceId: string | 'free-plan' | undefined;
  productId: string | 'free-plan' | undefined;
}

export function useSubscriptionHelpers() {
  const loading = useRecoilValue(subscriptionAtoms.loadingDocument);
  const products = useRecoilValue(subscriptionSelectors.productsSelector);
  const active = useRecoilValue(subscriptionAtoms.active);
  const end = useRecoilValue(subscriptionAtoms.end);
  const subscriptionId = useRecoilValue(subscriptionAtoms.subscriptionId);
  const current = useRecoilValue(
    subscriptionSelectors.getCurrentProductAndPrice,
  );
  const isGracePeriod = useRecoilValue(subscriptionAtoms.isGracePeriod);

  const isSubscribed = useMemo(() => Boolean(subscriptionId), [subscriptionId]);
  const isInactive = useMemo(
    () => isSubscribed && !active,
    [isSubscribed, active],
  );
  const isExpired = useMemo(
    () => isSubscribed && end && typeof end === 'number' && Date.now() > end,
    [isSubscribed, end],
  );
  const isSubscriptionValid = useMemo(
    () =>
      Boolean(
        !loading &&
          isSubscribed &&
          end &&
          typeof end === 'number' &&
          Date.now() < end,
      ) || end === 'permanent',
    [loading, isSubscribed, end],
  );

  function isCurrentPlan({ priceId, productId }: SubscriptionProductProps) {
    if (!current || !priceId || !productId) return false;
    return current.price.id === priceId && current.product.id === productId;
  }

  function isCurrentProduct(productId: string) {
    if (!current || !productId) return false;
    return current.product.id === productId;
  }

  /** Renewing subscription = subscription has completely expired. */
  function isRenewingSubscription(priceId: string | undefined) {
    return current?.price.id === priceId && isExpired;
  }

  function isFreePlan({ productId, priceId }: SubscriptionProductProps) {
    if (!productId || !priceId) return false;
    const product = products.find((p) => p.id === productId);
    const price = product?.prices.find((p) => p.id === priceId);

    if (!product || !price) return false;
    return price.unit_amount === 0;
  }

  return {
    isCurrentPlan,
    isCurrentProduct,
    isRenewingSubscription,
    isFreePlan,
    isSubscribed,
    isInactive,
    isExpired,
    isSubscriptionValid,
    isGracePeriod,
    loading,
  };
}
