import publishIconMenu from 'assets/icons/icon-publish-12-negative.svg';
import publishIconBtn from 'assets/icons/icon-publish-12-white.svg';
import { authenticationSelector } from 'modules/authentication';
import {
  FACELIFT_RELEASE_DATE,
  PublishedProposalsLimitModal,
  useProposalsByStatusSelector,
} from 'modules/proposals';
import { useSetOverviewUpgradePlusModalOpen } from 'modules/proposals-overview';
import { Proposal } from 'modules/proposals/Proposal';
import { EmailVerificationModal } from 'modules/settings';
import { subscriptionSelectors } from 'modules/subscription';
import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useRecoilValue } from 'recoil';
import { Button } from 'shared/components';
import { useModal } from 'shared/hooks';

interface Props {
  className: string;
  isDrawerBtn?: boolean;
}

const isDevelopment = process.env.NODE_ENV === 'development';

export const PublishButton: React.FC<Props> = ({ className, isDrawerBtn }) => {
  const {
    isModalOpen: isEmailVerificationModalOpen,
    closeModal: closeEmailVerificationModal,
    openModal: openEmailVerificationModal,
  } = useModal();
  const {
    isModalOpen: isPublishedProposalsLimitModalOpen,
    closeModal: closePublishedProposalsLimitModal,
    openModal: openPublishedProposalsLimitModal,
  } = useModal();
  const { user } = useSelector(authenticationSelector.getState);
  const isTwitterLogin = useSelector(authenticationSelector.isTwitterLogin);
  const { setValue, getValues } =
    useFormContext<PublishStatusAware<Proposal>>();
  const setIsPlusUpgradeModalOpen = useSetOverviewUpgradePlusModalOpen();
  const isTierOne = useRecoilValue(subscriptionSelectors.isTierOne);
  const published = useProposalsByStatusSelector('published');
  const newPublished = useMemo(
    () =>
      published?.filter(
        (proposal) => proposal.creationDateTime > FACELIFT_RELEASE_DATE,
      ),
    [published],
  );

  function handlePublish(event: React.MouseEvent<HTMLButtonElement>) {
    if (!user?.emailVerified && !isTwitterLogin && !isDevelopment) {
      event.preventDefault();
      openEmailVerificationModal();
      return;
    }

    const isOldProposal = getValues('creationDateTime') < FACELIFT_RELEASE_DATE;
    if (newPublished.length >= 3 && !isTierOne && !isOldProposal) {
      event.preventDefault();
      setIsPlusUpgradeModalOpen(true);
      openPublishedProposalsLimitModal();
      return;
    }

    setValue('nextPublishStatus', 'published');
  }

  function handleClosePublishedProposalsLimitModal() {
    setIsPlusUpgradeModalOpen(false);
    closePublishedProposalsLimitModal();
  }

  return (
    <>
      <Button
        className={className}
        onClick={handlePublish}
        btnSelector="publish-proposal-btn"
        type="submit"
      >
        {isDrawerBtn ? (
          <img
            src={publishIconMenu}
            alt="PublishIcon"
            className="btn__icon btn__icon__left"
          />
        ) : (
          <img
            src={publishIconBtn}
            alt="PublishIcon"
            className="btn__icon btn__icon__left"
          />
        )}
        <span>
          <FormattedMessage id="buttons.publish" />
        </span>
      </Button>
      {isEmailVerificationModalOpen && (
        <EmailVerificationModal
          email={user?.email || ''}
          onClose={closeEmailVerificationModal}
        />
      )}
      {isPublishedProposalsLimitModalOpen && (
        <PublishedProposalsLimitModal
          onClose={handleClosePublishedProposalsLimitModal}
        />
      )}
    </>
  );
};
