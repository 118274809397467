import { ManageSidebar } from 'modules/dashboard';
import { SettingsIconBack } from 'modules/settings';
import { StripePlansController } from 'modules/subscription';
import React, { PropsWithChildren } from 'react';
import { DashboardSidebarFooter, Header, Main } from 'shared/components';
import { AdminGuard } from '../admin-guard';

export const AdminLayout: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <AdminGuard>
      <div className="dashboard__container">
        <div className="dashboard__sidebar">
          <div className="dashboard__sidebar__content">
            <ManageSidebar showNotice={false} />
          </div>
          <DashboardSidebarFooter className="dashboard__sidebar__footer__sticky" />
        </div>
        <div className="dashboard__content">
          <Header />
          <Main settingsBackComponent={SettingsIconBack}>{children}</Main>
        </div>
        <StripePlansController />
        <div id="global-modal" />
      </div>
    </AdminGuard>
  );
};
