import sparksIcon from 'assets/icons/icon-sparks-16.svg';
import {
  PropozePlusUpgradeModal,
  usePropozePlusModal,
} from 'modules/subscription';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'shared/components';

interface Props {
  noticeActionId?: string;
  noticeDescriptionId?: string;
  containerClassName?: string;
  onCloseSlidingMenu?: (menuOpen: boolean) => void;
}

export const FreeTierNotice: React.FC<Props> = ({
  noticeActionId = 'propoze-plus-notice.free.action',
  noticeDescriptionId = 'propoze-plus-notice.free.description',
  containerClassName,
  onCloseSlidingMenu,
}) => {
  const { isModalVisible, openModal, closeModal } =
    usePropozePlusModal(onCloseSlidingMenu);

  return (
    <div
      className={`propoze-plus-notice__wrapper ${containerClassName || ''}`}
      data-cy="propoze-plus-notice"
      onClick={openModal}
    >
      <img
        src={sparksIcon}
        alt="Sparks icon"
        className="btn__icon btn__icon__left"
      />
      <div className="d--ib">
        <Button
          type="button"
          onClick={openModal}
          className="btn__slim t-primary t-bold text--xsm"
          btnSelector="propoze-plus-notice-upgrade-btn"
        >
          <FormattedMessage id={noticeActionId} />
        </Button>
        <span className="text--xsm t-text-2">
          <FormattedMessage id={noticeDescriptionId} />
        </span>
      </div>
      {isModalVisible && <PropozePlusUpgradeModal onClose={closeModal} />}
    </div>
  );
};
