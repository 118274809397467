import { useOrganizationIdSelector } from 'modules/authorization';
import { useUserFeedback } from 'modules/settings';
import { subscriptionAtoms } from 'modules/subscription';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import {
  Button,
  Checkbox,
  LoadingSpinner,
  ModalFooter,
  ModalHeader,
} from 'shared/components';
import { useCloseOnEscape, useSubmitOnEnter } from 'shared/hooks';
import { convertDate } from 'shared/utils';

interface Props extends ModalProps {
  hasActivePlusSubscription: boolean;
  onSetCurrentLocalStep: (currentLocalStep: deleteAccountSteps) => void;
}

export const DeleteAccountConfirmModal: React.FC<Props> = ({
  hasActivePlusSubscription,
  onSetCurrentLocalStep,
  onClose,
}) => {
  const { formatMessage } = useIntl();
  const closeButtonRef = useCloseOnEscape<HTMLButtonElement>();
  const submitButtonRef = useSubmitOnEnter<HTMLButtonElement>();
  const { sendDeleteAccountFeedback } = useUserFeedback();
  const end = useRecoilValue(subscriptionAtoms.end);
  const organizationId = useOrganizationIdSelector();

  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [consent, setConsent] = useState(false);

  const buttonDisabled = hasActivePlusSubscription && !consent;

  function toggleVisibilityValue() {
    setConsent(!consent);
  }

  async function handleSubmit() {
    setLoading(true);

    if (feedback.trim()) {
      await sendDeleteAccountFeedback({ feedback, organizationId });
    }

    onSetCurrentLocalStep('status');
    setLoading(false);
  }

  return (
    <>
      <ModalHeader>
        <p className="account-delete-modal__header text--xxl__responsive">
          <FormattedMessage id="account-settings.delete-confirm-modal.title" />
        </p>
      </ModalHeader>
      <div className="account-delete-modal__container">
        <p className="s-bottom--med t-eta">
          <FormattedMessage id="account-settings.delete-confirm-modal.description" />
        </p>
        <div className="f--col u-width--full s-top--sml">
          <div className="f f--col s-top--tny">
            <p className="s-bottom--tny t-label">
              <FormattedMessage id="account-settings.delete-feedback-modal.input.feedback.label" />
            </p>
            <textarea
              className="input input__box account-delete-modal__textarea input__textarea"
              value={feedback}
              disabled={loading}
              autoFocus
              rows={5}
              onChange={({ currentTarget: { value } }) => setFeedback(value)}
              placeholder={formatMessage({
                id: 'account-settings.delete-feedback-modal.input.feedback.placeholder',
              })}
            />
          </div>

          {hasActivePlusSubscription && (
            <>
              {end === 'permanent' ? (
                <p className="downgrade-modal__description text--sm s-bottom--sml">
                  <FormattedMessage id="account-settings.delete-confirm-modal.description.life-time" />
                </p>
              ) : (
                <p className="downgrade-modal__description text--sm s-bottom--sml">
                  <FormattedMessage
                    id="account-settings.delete-confirm-modal.description.action"
                    values={{
                      date: (
                        <b>{typeof end === 'number' && convertDate(end)}</b>
                      ),
                    }}
                  />
                </p>
              )}
              <Checkbox
                isChecked={consent}
                checkmarkClassName="account-delete-modal__checkbox__container"
                handleToggle={toggleVisibilityValue}
              />
              <p
                onClick={toggleVisibilityValue}
                className="t-eta s-left--lrg s-bottom--sml c-pointer"
              >
                <FormattedMessage id="account-settings.delete-confirm-modal.checkbox" />
              </p>
            </>
          )}
        </div>
      </div>

      <ModalFooter>
        <div className="btn-group btn-group--simple">
          <Button
            size="lrg"
            style="outline"
            onClick={onClose}
            ref={closeButtonRef}
            btnSelector="cancel-btn"
          >
            <FormattedMessage id="account-settings.delete-modal.action.cancel" />
          </Button>
          {loading ? (
            <Button
              type="button"
              disabled={loading}
              size="lrg"
              className="btn__destructive__loading"
              btnSelector="processing-btn"
            >
              <LoadingSpinner height={12} width={12} type="warning" />
              <p className="s-left--med">
                <FormattedMessage id="subscription-action-controller.action.downgrading" />
              </p>
            </Button>
          ) : (
            <Button
              size="lrg"
              style="destructive"
              onClick={handleSubmit}
              ref={submitButtonRef}
              disabled={loading || buttonDisabled}
              btnSelector="confirm-modal-delete-account-btn"
            >
              <FormattedMessage id="account-settings.delete-modal.action.confirm" />
            </Button>
          )}
        </div>
      </ModalFooter>
    </>
  );
};
