import { doc, onSnapshot } from 'firebase/firestore';
import { authenticationSelector } from 'modules/authentication';
import { useOrganizationIdSelector } from 'modules/authorization';
import { db } from 'modules/firebase';
import { defaultCustomPresets } from 'modules/proposals';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSetRecoilState } from 'recoil';
import { settingsFirestoreConverter } from '../const/settingsFirestoreConverter';
import { settingsAction } from '../redux';
import { customPresetsAtom, useSetConfig } from '../state';

export function useSettingsSubscription() {
  const setConfig = useSetConfig();
  const organizationId = useOrganizationIdSelector();
  const setCustomPresets = useSetRecoilState(customPresetsAtom);
  const { user } = useSelector(authenticationSelector.getState);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!user) return;

    const document = doc(db, organizationId, 'settings').withConverter(
      settingsFirestoreConverter,
    );

    return onSnapshot(
      document,
      (snapshot) => {
        let customPresets = snapshot.data()?.customPresets;
        if (Array.isArray(customPresets)) {
          customPresets = customPresets.filter(
            (preset) => preset.title === 'Default' || preset.title === 'Dark',
          );
        }
        if (!customPresets || customPresets.length === 0) {
          customPresets = defaultCustomPresets;
        }
        setCustomPresets(customPresets);
        setConfig(snapshot.data() || null);
        dispatch(settingsAction.onSnapshotUpdate(snapshot.data() || null));
      },
      (error) => dispatch(settingsAction.onSnapshotError(error)),
    );
  }, [organizationId, user]);
}
