import { authenticationSelector } from 'modules/authentication';
import { settingsSelector } from 'modules/settings';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useRecoilValue } from 'recoil';
import { toast } from 'sonner';
import { subscriptionAtoms } from '../state';
import { useFreeSubscription } from './useFreeSubscription';

export function useAutoFreeSubscription() {
  const { user } = useSelector(authenticationSelector.getState);
  const { config } = useSelector(settingsSelector.getState);
  const customerId = useRecoilValue(subscriptionAtoms.customerId);
  const subscriptionId = useRecoilValue(subscriptionAtoms.subscriptionId);
  const loadingDocument = useRecoilValue(subscriptionAtoms.loadingDocument);
  const [isCreatingSubscription, setIsCreatingSubscription] = useState(false);

  const { formatMessage } = useIntl();
  const { handleFreeSubscription } = useFreeSubscription();

  useEffect(() => {
    // Stop execution if not all data is available yet.
    if (!config || loadingDocument || isCreatingSubscription) return;

    // Should create subscription if user exists, but no stripe information is available.
    const shouldCreateSubscription = user && !customerId && !subscriptionId;
    if (!shouldCreateSubscription) return;

    setIsCreatingSubscription(true);
    handleFreeSubscription()
      .catch(() =>
        toast.error(
          formatMessage({
            id: 'free-subscription.subscribe.api.error',
          }),
        ),
      )
      .finally(() => setIsCreatingSubscription(false));
  }, [user, config, customerId, subscriptionId, loadingDocument]);
}
