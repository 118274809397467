import { useEffect, useState } from 'react';

/**
 * Hook that observes segment sections on a page and sets the active section
 * based on viewport visibility using the Intersection Observer API.
 *
 * @param {string[]} sections - An array of section IDs to be observed.
 */

export function useActiveSectionObserver(sections: string[]) {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [activeSection, setActiveSection] = useState<string | null>(
    sections[0],
  );

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries: IntersectionObserverEntry[]) => {
        const visibleEntries = entries.filter(
          (entry) => entry.isIntersecting && entry.intersectionRatio > 0.5,
        );
        if (!visibleEntries.length) return;

        visibleEntries.forEach((entry) => {
          if (activeIndex) {
            setActiveSection(sections[activeIndex]);
            setActiveIndex(null);
            return;
          }
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
            return;
          }
        });
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
      },
    );

    // Observing each section.
    sections.forEach((section) => {
      const element = document.getElementById(section);
      if (element) observer.observe(element);
    });

    // Clean-up function to unobserve all sections when the component unmounts or sections change.
    return () => {
      sections.forEach((section) => {
        const element = document.getElementById(section);
        if (element) observer.unobserve(element);
      });
    };
  }, [sections, activeIndex]);

  return {
    activeSection,
    setActiveIndex,
  };
}
