import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ListErrorMessage } from './ListErrorMessage';

export const ListError: React.FC = () => {
  return (
    <div className="dashboard__overview__list__error">
      {/* 
      TODO @markoboras0712 when the ilustration is ready, uncomment this
      <motion.img
        style={{
          opacity: 0,
          y: 8,
        }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, type: 'intertia' }}
        src={proposalNotFoundIlustration}
        alt="Proposal not found ilustration"
        className="dashboard__overview__list__error--img"
      /> */}
      <ListErrorMessage
        message={
          <FormattedMessage id="proposals.overview.search.no-results.title" />
        }
        description={
          <FormattedMessage id="proposals.overview.search.no-results.description" />
        }
      />
    </div>
  );
};
