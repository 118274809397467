import { useScrollIntoView } from 'modules/authentication';
import { useAppSumo } from 'modules/settings';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Button,
  FieldWrapper,
  LoadingSpinner,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from 'shared/components';
import { useCloseOnEscape, useSubmitOnEnter } from 'shared/hooks';

interface Props extends ModalProps {
  onSetCurrentLocalStep: (step: AppSumoSteps) => void;
}

export const AppSumoFormModal: React.FC<Props> = ({
  onClose,
  onSetCurrentLocalStep,
}) => {
  const { formatMessage } = useIntl();
  const { handleFocus } = useScrollIntoView();
  const closeButtonRef = useCloseOnEscape<HTMLButtonElement>();
  const submitButtonRef = useSubmitOnEnter<HTMLButtonElement>();
  const { form, isLoading, onSubmit } = useAppSumo(onSetCurrentLocalStep);

  return (
    <FormProvider {...form}>
      <ModalHeader>
        <p className="text--xl__responsive">
          <FormattedMessage id="settings.subscription.appsumo-modal-title" />
        </p>
      </ModalHeader>
      <ModalContent>
        <FieldWrapper
          name="code"
          labelId="settings.subscription.appsumo-label"
          errors={form.formState.errors}
          isRequired
        >
          <input
            type="text"
            className={`input input__box input__med ${
              form.formState.errors.code ? 'input__box__error' : ''
            }`}
            placeholder={formatMessage({
              id: 'settings.subscription.appsumo-placeholder',
            })}
            data-cy="appsumo-code-input"
            onFocus={handleFocus}
            autoComplete="password"
            {...form.register('code', {
              required: formatMessage({
                id: 'settings.subscription.appsumo-input-required',
              }),
              minLength: {
                value: 3,
                message: formatMessage({
                  id: 'settings.subscription.appsumo-input-min-length',
                }),
              },
            })}
          />
        </FieldWrapper>
      </ModalContent>
      <ModalFooter>
        <div className="btn-group btn-group--simple">
          <Button
            size="lrg"
            style="outline"
            onClick={onClose}
            ref={closeButtonRef}
            btnSelector="cancel-btn"
          >
            <FormattedMessage id="buttons.cancel" />
          </Button>
          {isLoading ? (
            <Button
              type="button"
              disabled={isLoading}
              size="lrg"
              className="u--width--full--mobile"
              style="primary"
            >
              <LoadingSpinner height={12} width={12} type="negative" />
              <p className="s-left--med">
                <FormattedMessage id="buttons.saving" />
              </p>
            </Button>
          ) : (
            <Button
              value="Save"
              size="lrg"
              type="submit"
              style="primary"
              className="u--width--full--mobile"
              btnSelector="company-settings-save-btn"
              onClick={form.handleSubmit(onSubmit)}
              ref={submitButtonRef}
            >
              <FormattedMessage id="buttons.redeem" />
            </Button>
          )}
        </div>
      </ModalFooter>
    </FormProvider>
  );
};
