import starIcon from 'assets/icons/icon-star-12.svg';
import successIcon from 'assets/icons/icon-success-12.svg';
import React from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
  productId: string;
  features: string[];
  isVatNoteVisible?: boolean;
  className?: string;
  featuresSelector?: string;
}

export const ProductFeatureList: React.FC<Props> = ({
  features,
  productId,
  isVatNoteVisible,
  className,
  featuresSelector,
}) => {
  if (!features.length) return null;

  return (
    <>
      <ul
        className={`list--bullets s-bottom--med list__item__bold ${
          className || ''
        }`}
        data-cy={featuresSelector}
      >
        {features.map((feature, index) => (
          <li
            key={`${productId}_feature_${index}`}
            className="f f--align-start g-4"
          >
            {feature.includes('Coming in') ? (
              <img
                src={starIcon}
                className="s-top--tny btn__icon__left"
                alt="Star Icon"
              />
            ) : (
              <img
                src={successIcon}
                className="s-top--tny btn__icon__left"
                alt="Success Icon"
              />
            )}
            <span className="text--sm t-text-1 s-left--tny">{feature}</span>
          </li>
        ))}
      </ul>
      {isVatNoteVisible && (
        <span className="t-text-2 subscription-product__group__vat__notice">
          <FormattedMessage id="subscription-select-modal.vat-notice" />
        </span>
      )}
    </>
  );
};
