import { PageProps } from 'gatsby';
import { authenticationSelector } from 'modules/authentication';
import {
  ChangePasswordModal,
  DeleteAccountModal,
  EmailVerificationStatus,
  SettingsLayout,
  SettingsNewsletter,
  useConfigSelector,
} from 'modules/settings';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button, SEO } from 'shared/components';
import { useModal } from 'shared/hooks';

export const AccountSettings: React.FC<PageProps> = () => {
  const { formatMessage } = useIntl();
  const {
    isModalOpen: isDeleteAccountModalOpen,
    closeModal: closeDeleteAccountModal,
    openModal: openDeleteAccountModal,
  } = useModal();
  const {
    isModalOpen: isChangePasswordModalOpen,
    closeModal: closeChangePasswordModal,
    openModal: openChangePasswordModal,
  } = useModal();

  const config = useConfigSelector();
  const { user } = useSelector(authenticationSelector.getState);
  const isTwitterLogin = useSelector(authenticationSelector.isTwitterLogin);

  return (
    <>
      <SEO
        title={formatMessage({ id: 'settings.seo.title' })}
        description={formatMessage({ id: 'settings.seo.description' })}
        href={location.href}
      />
      <SettingsLayout>
        <header
          className="dashboard__section__header__title text--xs t-bold"
          data-cy="settings-account-settings-title"
        >
          <FormattedMessage id="settings.account.title" />
        </header>
        <div className="dashboard__section__mobile__actions">
          <header
            className="text--xxl__responsive t-bold"
            data-cy="settings-account-settings-mobile-title"
          >
            <FormattedMessage id="settings.account.title" />
          </header>
        </div>

        <section className="settings">
          {!user?.emailVerified && !isTwitterLogin && (
            <EmailVerificationStatus email={user?.email || ''} />
          )}
          <div>
            <span
              className="settings__header__title text--xs t-bold"
              data-cy="account-settings-title"
            >
              <FormattedMessage id="account-settings.section.change-password.title" />
            </span>
            <p className="t-eta t-text-2 s-top--med">
              <FormattedMessage id="account-settings.section.change-password.description" />
            </p>
            <Button
              type="button"
              size="med"
              style="secondary"
              className="s-top--lrg"
              onClick={openChangePasswordModal}
              btnSelector="change-password-btn"
            >
              <FormattedMessage id="account-settings.section.change-password.action" />
            </Button>
          </div>
          <div className="divider" />
          {config && <SettingsNewsletter config={config} />}
          <div className="divider" />
          <div>
            <span
              className="settings__header__title text--xs t-bold"
              data-cy="account-settings-title"
            >
              <FormattedMessage id="account-settings.section.delete.action" />
            </span>
            <p className="t-eta t-text-2 s-top--med">
              <FormattedMessage id="settings.account.description" />
            </p>
            <Button
              type="button"
              size="med"
              style="destructive__outline"
              className="s-top--lrg"
              onClick={openDeleteAccountModal}
              btnSelector="delete-account-btn"
            >
              <FormattedMessage id="account-settings.section.delete.action" />
            </Button>
          </div>
        </section>
        {isDeleteAccountModalOpen && (
          <DeleteAccountModal onClose={closeDeleteAccountModal} />
        )}
        {isChangePasswordModalOpen && (
          <ChangePasswordModal onClose={closeChangePasswordModal} />
        )}
      </SettingsLayout>
    </>
  );
};
