import { useLocation } from '@reach/router';
import { useDomain } from 'shared/hooks';
import { useStripeCheckoutCallables } from './useStripeCheckoutCallables';

/** Stripe checkout functions and helpers.
 * If using anything from useStripeCallables, @param functionsInitialised **MUST** be set,
 * otherwise you risk the callable function not being instantiated */

export function useStripeCheckout() {
  const currentDomain = useDomain();
  const { pathname } = useLocation();

  const { functionsInitialised, createBillingPortalSession } =
    useStripeCheckoutCallables();

  async function createBillingPortal(customerId: string) {
    if (!createBillingPortalSession) return;
    try {
      const { data } = await createBillingPortalSession({
        customerId,
        returnUrl: `${currentDomain}${pathname}`,
      });

      if (!data.success) throw new Error(data.message);
      return data.payload;
    } catch (error) {
      console.error(error);
      return;
    }
  }

  return {
    functionsInitialised,
    createBillingPortal,
  };
}
