import { settingsSelector } from 'modules/settings';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import {
  Button,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from 'shared/components';
import { useCloseOnEscape, useSubmitOnEnter } from 'shared/hooks';

interface Props extends ModalProps {
  onSetCurrentLocalStep: (currentLocalStep: deleteAccountSteps) => void;
}

export const DeleteAccountInfoModal: React.FC<Props> = ({
  onSetCurrentLocalStep,
  onClose,
}) => {
  const { config } = useSelector(settingsSelector.getState);
  const closeButtonRef = useCloseOnEscape<HTMLButtonElement>();
  const submitButtonRef = useSubmitOnEnter<HTMLButtonElement>();

  return (
    <>
      <ModalHeader>
        <p className="account-delete-modal__header text--xxl__responsive">
          <FormattedMessage id="account-settings.delete-info-modal.title" />
        </p>
      </ModalHeader>
      <div className="account-delete-modal__container">
        <ModalContent>
          <p>
            <FormattedMessage
              id="account-settings.delete-info-modal.description"
              values={{
                companyName: <b>{config && config.name}</b>,
              }}
            />
          </p>
          <ul className="list--bullets t-eta s-top--med t-text-1 account-delete-modal__list">
            <li>
              <FormattedMessage id="account-settings.delete-info-modal.description.bullet1" />
            </li>
            <li>
              <FormattedMessage id="account-settings.delete-info-modal.description.bullet2" />
            </li>
          </ul>
        </ModalContent>
      </div>

      <ModalFooter>
        <div className="btn-group btn-group--simple">
          <Button
            size="lrg"
            style="outline"
            onClick={onClose}
            ref={closeButtonRef}
            btnSelector="cancel-btn"
          >
            <FormattedMessage id="account-settings.delete-modal.action.cancel" />
          </Button>
          <Button
            size="lrg"
            style="destructive"
            onClick={() => onSetCurrentLocalStep('confirm')}
            ref={submitButtonRef}
            btnSelector="info-modal-delete-account-btn"
          >
            <FormattedMessage id="buttons.confirm" />
          </Button>
        </div>
      </ModalFooter>
    </>
  );
};
