import React from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
  product: StripeProductWithPrices;
  active: Subscription['active'];
  isGracePeriod?: boolean | null;
  end?: number | 'permanent' | null;
}

export const CurrentPlanCardHeader: React.FC<Props> = ({
  product,
  active,
  isGracePeriod,
  end,
}) => {
  function renderPlanCardLabel() {
    if (isGracePeriod) {
      return (
        <p
          className="subscription-settings-plan__status text--tny subscription-settings-plan__status--grace-period"
          data-cy="plan-status-label"
        >
          <FormattedMessage id="current-plan-card.label.grace-period" />
        </p>
      );
    }

    return (
      <p
        className={`subscription-settings-plan__status text--tny__responsive ${
          active && 'subscription-settings-plan__status--active'
        }`}
        data-cy="plan-status-label"
      >
        {active ? (
          <FormattedMessage id="current-plan-card.label.active" />
        ) : (
          <FormattedMessage id="current-plan-card.label.inactive" />
        )}
      </p>
    );
  }

  /**
   *
   * This is 'hack' for the plan name to be displayed as 'Plus Monthly' and 'Plus Yearly' instead of 'Monthly Plus' and 'Yearly Plus' since we fetch all stripe related data from Stripe.
   * I don't want to rename plan names to avoid unnecessary issues with cache.
   */
  function renderPlanName() {
    if (end === 'permanent') return 'Plus Lifetime';
    if (product.name === 'Monthly Plus') return 'Plus Monthly';
    if (product.name === 'Yearly Plus') return 'Plus Yearly';

    return product.name;
  }

  return (
    <div
      className="f f--align-center f--justify-between s-bottom--med"
      data-cy="plan-name-title"
    >
      <h2 className="text--lg__responsive t-bold t-text-1">
        {renderPlanName()}
      </h2>
      {renderPlanCardLabel()}
    </div>
  );
};
