import userAvatarIconOff from 'assets/icons/icon-user-avatar-off-32.svg';
import { AnimatePresence, motion } from 'framer-motion';
import { Link } from 'gatsby';
import {
  authenticationSelector,
  useAuthentication,
} from 'modules/authentication';
import {
  authorizationSelector,
  useOrganizationIdSelector,
} from 'modules/authorization';
import { useConfigSelector } from 'modules/settings';
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
  useAnimatedDropdown,
  useClickOutsideElement,
  useSupportMail,
} from 'shared/hooks';
import { Button } from '../button';
import { Loading } from '../loading';
import { AccountVerificationStatus } from './fragments';

export const NavDropdown: React.FC = () => {
  const { formatMessage } = useIntl();
  const { logout } = useAuthentication();

  const config = useConfigSelector();
  const supportMail = useSupportMail();
  const organizationId = useOrganizationIdSelector();
  const { user } = useSelector(authenticationSelector.getState);
  const isTwitterLogin = useSelector(authenticationSelector.isTwitterLogin);

  const { roles, adminRole } = useSelector(authorizationSelector.getState);

  const { isOpen, handleCloseDropdownToggle, handleOpenDropdownToggle } =
    useAnimatedDropdown();
  const drawerRef = useClickOutsideElement<HTMLDivElement>(
    handleCloseDropdownToggle,
    true,
    true,
  );

  function toggleDropdown(event: React.MouseEvent<HTMLDivElement>) {
    if (
      event.target instanceof HTMLElement &&
      event.target.closest('#dropdown-companyselector-signout-btn')
    ) {
      return;
    }

    if (
      event.target instanceof HTMLElement &&
      (!event.target.closest('#company__selector') ||
        event.target.closest('#company__selector__list'))
    ) {
      if (isOpen) {
        handleCloseDropdownToggle();
      } else {
        handleOpenDropdownToggle();
      }
    }
  }

  const dropdownRef = isOpen ? drawerRef : null;
  const currentOrganizationRole = useMemo(
    () => roles?.find((item) => item.organizationId === organizationId),
    [roles, organizationId],
  );
  const organizationName =
    currentOrganizationRole?.organizationName || config?.name;

  function handleUserAvatar() {
    if (config?.companyImage) return config?.companyImage;
    return userAvatarIconOff;
  }

  if (config === undefined) return <Loading type="user-avatar" />;

  return (
    <section
      ref={dropdownRef}
      onClick={toggleDropdown}
      className={`companyselector ${isOpen ? 'is-active' : ''}`}
    >
      <div
        data-cy="companyselector-dropdown-btn"
        className="f companyselector__navdropdown"
      >
        <img
          src={handleUserAvatar()}
          className="user-avatar__icon btn__icon__left"
          alt="userAvatarIcon"
        />

        <span className="companyselector__company__name text--sm t-bold t-shorten t-ellipsis d--ib f--align-self-center">
          {organizationName}
        </span>
      </div>
      <motion.svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        className="btn__icon f--shrink-zero"
        onClick={isOpen ? handleCloseDropdownToggle : handleOpenDropdownToggle}
        animate={{ rotate: isOpen ? -180 : 0 }}
        transition={{ duration: 0.2, ease: 'easeOut' }}
        fill="none"
      >
        <path
          d="M9.20121 4.99822C9.29953 4.90003 9.36133 4.75899 9.37299 4.60614C9.38465 4.45328 9.34523 4.30112 9.2634 4.18314C9.18157 4.06516 9.06403 3.99102 8.93663 3.97702C8.80924 3.96303 8.68243 4.01033 8.58411 4.10852L6 6.69374L3.41589 4.10852C3.36721 4.0599 3.31102 4.02326 3.25053 4.0007C3.19004 3.97814 3.12644 3.97009 3.06337 3.97702C3.00029 3.98395 2.93896 4.00572 2.8829 4.04109C2.82683 4.07645 2.77712 4.12472 2.7366 4.18314C2.65477 4.30112 2.61535 4.45328 2.62701 4.60614C2.63867 4.75899 2.70047 4.90003 2.79879 4.99822L5.69145 7.89062C5.77808 7.9772 5.88726 8.02461 6 8.02461C6.11274 8.02461 6.22192 7.9772 6.30855 7.89062L9.20121 4.99822Z"
          fill="#706F6F"
        />
      </motion.svg>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            ref={drawerRef}
            className="companyselector__dropdown"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.2, ease: [0.33, 1, 0.68, 1] }}
          >
            {roles?.map((item, index) => (
              <div
                key={item.organizationId || index}
                id="company__selector"
                className="companyselector__company"
              >
                <div
                  className="f f--align-center"
                  data-cy="dropdown-companyselector-company-name"
                >
                  <span className="t-text-1 text--sm t-bold companyselector__company__name">
                    {organizationName}
                  </span>
                  <AccountVerificationStatus
                    isVerified={user?.emailVerified || isTwitterLogin}
                  />
                </div>

                <span
                  className="companyselector__company__email text--xsm t-ellipsis-2-row"
                  data-cy="dropdown-companyselector-email"
                >
                  {user?.email}
                </span>
              </div>
            ))}
            <div className="divider--verylight-5" />
            <ul
              className="companyselector__buttons"
              id="company__selector__list"
            >
              {adminRole === 'superAdmin' && (
                <>
                  <li>
                    <Link
                      to={formatMessage({ id: 'routes.admin.users' })}
                      activeClassName="is-active"
                      state={{
                        lastPageUrl: location.pathname,
                      }}
                      data-cy="dropdown-admin-dashboard"
                      className="text--sm__responsive"
                    >
                      <FormattedMessage id="settings.sections.admin-dashboard" />
                    </Link>
                  </li>
                </>
              )}
              <li>
                <Link
                  to={formatMessage({ id: 'routes.clients' })}
                  activeClassName="is-active"
                  state={{
                    lastPageUrl: location.pathname,
                  }}
                  data-cy="dropdown-companyselector-manage"
                  className="text--sm__responsive"
                >
                  <FormattedMessage id="buttons.manage" />
                </Link>
              </li>
              <li>
                <Link
                  to={formatMessage({ id: 'routes.company-info' })}
                  activeClassName="is-active"
                  state={{
                    lastPageUrl: location.pathname,
                  }}
                  data-cy="dropdown-companyselector-company-settings"
                  className="text--sm__responsive"
                >
                  <FormattedMessage id="buttons.settings" />
                </Link>
              </li>
              <li>
                <Link
                  to={formatMessage({ id: 'routes.subscription-settings' })}
                  activeClassName="is-active"
                  state={{
                    lastPageUrl: location.pathname,
                  }}
                  data-cy="dropdown-companyselector-manage-account"
                  className="text--sm__responsive"
                >
                  <FormattedMessage id="settings.sections.account-management" />
                </Link>
              </li>
              <div className="divider--verylight-5" />
              <li>
                <a
                  href={formatMessage({ id: 'routes.pricing' })}
                  data-cy="dropdown-companyselector-pricing-plans"
                  className="text--sm__responsive"
                  rel="noreferrer"
                >
                  <FormattedMessage id="buttons.pricing-plans" />
                </a>
              </li>
              <li>
                <a
                  href={supportMail}
                  data-cy="dropdown-companyselector-get-in-touch"
                  className="text--sm__responsive"
                >
                  <FormattedMessage id="buttons.get-in-touch" />
                </a>
              </li>
              <div className="divider--verylight-5" />
              <li>
                <Button
                  onClick={logout}
                  id="dropdown-companyselector-signout-btn"
                  className="text--sm__responsive companyselector__buttons__signout"
                  btnSelector="dropdown-companyselector-signout-btn"
                >
                  <FormattedMessage id="buttons.sign-out" />
                </Button>
              </li>
            </ul>
            <div className="companyselector__footer">
              <a
                className="s-right--sml t-eta t-bg-faded"
                href={formatMessage({ id: 'links.faq' })}
                data-cy="dropdown-companyselector-faq-link"
                rel="noopener noreferrer"
              >
                <FormattedMessage id="buttons.faq" />
              </a>
              <span className="t-small t-bg-faded footer__terms-service__divider">
                •
              </span>
              <a
                className="s-left--sml s-right--sml t-eta t-bg-faded"
                href={formatMessage({ id: 'routes.privacy-policy' })}
                data-cy="dropdown-companyselector-privacy-policy-link"
                rel="noopener noreferrer"
              >
                <FormattedMessage id="buttons.privacy-policy" />
              </a>
              <span className="t-small t-bg-faded footer__terms-service__divider">
                •
              </span>
              <a
                className="s-left--sml t-eta t-bg-faded"
                href={formatMessage({ id: 'routes.terms-of-service' })}
                data-cy="dropdown-companyselector-tos-link"
                rel="noopener noreferrer"
              >
                <FormattedMessage id="buttons.terms-of-service" />
              </a>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </section>
  );
};
