import { CSSProperties } from 'react';

export function generateButtonStyle(
  buttonColor: string,
  isHovered: boolean,
): CSSProperties {
  const hasCustomButtonColor = buttonColor !== '#8039FF';
  const opacity = isHovered && hasCustomButtonColor ? 0.8 : 1;

  return {
    ...(hasCustomButtonColor && { background: buttonColor }),
    opacity,
  };
}

export function generateFileButtonStyle(
  buttonColor: string,
  typography: ProposalTypography,
  isHovered: boolean,
): CSSProperties {
  const hasCustomButtonColor = buttonColor !== '#8039FF';
  const opacity = isHovered && hasCustomButtonColor ? 0.8 : 1;

  return {
    ...(hasCustomButtonColor && {
      borderColor: buttonColor,
      color: buttonColor,
    }),
    opacity,
    fontFamily: typography.body.fontFamily,
    fontWeight: typography.body.fontFamily,
  };
}
