import { authenticationSelector } from 'modules/authentication';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button, LoadingSpinner } from 'shared/components';
import { useSubscriptionHelpers } from '..';

interface Props {
  priceId: string;
  productId: string;
  isCheckoutLoading?: boolean;
  onSelect: (priceId: string) => void;
  index?: number;
  name?: string;
}

export const ProductItemAction: React.FC<Props> = ({
  priceId,
  productId,
  isCheckoutLoading,
  onSelect,
  index,
  name,
}) => {
  const [loading, setLoading] = useState(false);

  const { user } = useSelector(authenticationSelector.getState);

  const { isCurrentPlan, isSubscribed, isExpired, isInactive, isGracePeriod } =
    useSubscriptionHelpers();
  const { isFreePlan } = useSubscriptionHelpers();

  const isCurrent = isCurrentPlan({ priceId, productId });
  const isFree = isFreePlan({ priceId, productId });
  const isPaidCheckoutLoading =
    isCheckoutLoading && !isFreePlan({ productId, priceId });

  function handleSelectPlan() {
    setLoading(true);
    onSelect(priceId);
  }

  /**User has active free plan.*/
  if (isCurrent && isFree) return null;

  /**User has cancelled his current subscription and he needs to reactivate that one first  */
  if (!isCurrent && isInactive) return null;

  if (isPaidCheckoutLoading || loading) {
    return (
      <Button
        type="button"
        disabled={isCheckoutLoading || loading}
        size="lrg"
        style="outline"
        className="f--one"
        btnSelector="downgrade-proccesing-disabled"
      >
        <LoadingSpinner height={12} width={12} type="primary" />
        <p className="s-left--med">
          <FormattedMessage id="subscription-action-controller.action.downgrading" />
        </p>
      </Button>
    );
  }

  if (isFree && !user) {
    return (
      <Button
        type="button"
        onClick={handleSelectPlan}
        size="lrg"
        style="secondary"
        className="f--one"
        btnSelector={`select-plan-btn-${name}`}
      >
        <FormattedMessage id="subscription-product-item.free.card" />
      </Button>
    );
  }

  if (!isSubscribed || !user) {
    return (
      <Button
        type="button"
        size="lrg"
        style="primary"
        onClick={handleSelectPlan}
        className="f--one"
        btnSelector={`select-plan-btn-${name}`}
      >
        <FormattedMessage id="subscription-product-item.action" />
      </Button>
    );
  }

  if (isCurrent && isSubscribed && !isFree) {
    return (
      <Button
        type="button"
        size="lrg"
        style="primary"
        onClick={handleSelectPlan}
        className="f--one"
        btnSelector={`renew-plan-btn-${index}`}
      >
        <FormattedMessage id="current-plan-card.action.manage-plan" />
      </Button>
    );
  }

  if (isSubscribed && !isFree) {
    return (
      <Button
        type="button"
        size="lrg"
        style="primary"
        onClick={handleSelectPlan}
        className="f--one"
        btnSelector={`select-plan-btn-${name}`}
      >
        <FormattedMessage id="subscription-product-item.action" />
      </Button>
    );
  }

  if (isCurrent) {
    if (isExpired || isGracePeriod) {
      return (
        <Button
          type="button"
          size="lrg"
          style="primary"
          onClick={handleSelectPlan}
          className="f--one"
          btnSelector={`renew-plan-btn-${index}`}
        >
          <FormattedMessage id="subscription-product-item.action.renew" />
        </Button>
      );
    }

    if (isInactive) {
      return (
        <Button
          type="button"
          onClick={handleSelectPlan}
          size="lrg"
          style="primary"
          className="f--one"
          btnSelector="reactivate-plan-btn"
        >
          <FormattedMessage id="subscription-product-item.action.reactivate" />
        </Button>
      );
    }
  }

  /**Remove downgrade button on monthly plan in grace period */
  if (isGracePeriod && !isFree) return null;

  /**Remove upgrade button on yearly plan in grace period */
  if (isGracePeriod) return null;

  return null;
};
