import { useIntl } from 'react-intl';
import { useModal } from './useModal';

export function useModalGuard() {
  const { formatMessage } = useIntl();
  const { guardModalClose } = useModal();

  const handleClose = (
    name: string,
    nameInitialValue: string,
    onClose: VoidFunction,
    isDirty: boolean,
  ) => {
    if (nameInitialValue === name || !name.replace(/\s/g, '').length) {
      onClose();
      return;
    }

    if (isDirty) {
      guardModalClose(
        onClose,
        formatMessage({ id: 'buttons.confirm-navigation' }),
      );
      return;
    }

    onClose();
    return;
  };

  return { handleClose };
}
