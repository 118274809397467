function areUnitsEqual(unit1: Unit, unit2: Unit): boolean {
  return (
    unit1.id === unit2.id &&
    unit1.name === unit2.name &&
    unit1.index === unit2.index &&
    unit1.createdAt === unit2.createdAt
  );
}

export function areUnitArraysEqual(arr1?: Unit[], arr2?: Unit[]) {
  return (
    arr1?.length === arr2?.length &&
    arr2 &&
    arr1?.every((unit, index) => areUnitsEqual(unit, arr2[index]))
  );
}
