import {
  getExchangeRate,
  getNewCurrency,
  isDualPricingCurrency,
} from 'modules/proposals';
import { getCurrencyFromISOCountry } from 'shared/utils';
import { useISOCountry } from './useISOCountry';

export function useConversionRate() {
  const { ISOCountry } = useISOCountry();
  const oldCurrency = getCurrencyFromISOCountry(ISOCountry);

  const exchangeRate = getExchangeRate(oldCurrency).toLocaleString('de-DE', {
    minimumSignificantDigits: 4,
  });
  const newCurrency = getNewCurrency(oldCurrency);

  const isDualPricingEnabled = isDualPricingCurrency(oldCurrency);
  const conversionRate = `Conversion rate ${exchangeRate} ${oldCurrency} to 1 ${newCurrency}`;

  return { conversionRate, isDualPricingEnabled };
}
