import { Link, navigate } from 'gatsby';
import { mobileNavDropdownVisibleSelector } from 'modules/dashboard';
import { useProposalsByStatusSelector } from 'modules/proposals';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useRecoilState } from 'recoil';
import { useMobile } from 'shared/hooks';

interface Props {
  onToggleMenu?: (event: React.MouseEvent<HTMLElement>) => void;
}

export const DashboardSidebar: React.FC<Props> = ({ onToggleMenu }) => {
  const { formatMessage } = useIntl();

  const [mobileNavdropdownVisible, setMobileNavdropdownVisible] =
    useRecoilState(mobileNavDropdownVisibleSelector);

  const isMobile = useMobile();
  const drafts = useProposalsByStatusSelector('draft');

  function handleLink(
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    to: string,
  ) {
    event.preventDefault();
    if (!isMobile) {
      navigate(to);
      return;
    }
    if (mobileNavdropdownVisible) setMobileNavdropdownVisible(false);

    onToggleMenu?.(event);

    setTimeout(() => {
      navigate(to);
    }, 250);
  }

  return (
    <nav className="dashboard__sidebar__nav" onClick={onToggleMenu}>
      <ul>
        <Link
          activeClassName="is-active"
          to={formatMessage({ id: 'routes.overview' })}
          onClick={(event) =>
            handleLink(event, formatMessage({ id: 'routes.overview' }))
          }
          className="dashboard__sidebar__link"
          data-cy="dashboard-sidebar-overview"
        >
          <span>
            <FormattedMessage id="proposals.overview.title" />
          </span>
        </Link>
        <Link
          activeClassName="is-active"
          to={formatMessage({ id: 'routes.draft' })}
          onClick={(event) =>
            handleLink(event, formatMessage({ id: 'routes.draft' }))
          }
          className="dashboard__sidebar__link"
          data-cy="dashboard-sidebar-in-progress"
        >
          <span>
            <FormattedMessage id="proposals.draft.title" />
          </span>
          {drafts.length > 0 && (
            <span className="dashboard__sidebar__counter">
              ({drafts.length})
            </span>
          )}
        </Link>
        <Link
          activeClassName="is-active"
          to={formatMessage({ id: 'routes.published' })}
          onClick={(event) =>
            handleLink(event, formatMessage({ id: 'routes.published' }))
          }
          className="dashboard__sidebar__link"
          data-cy="dashboard-sidebar-published"
        >
          <span>
            <FormattedMessage id="proposals.published.title" />
          </span>
        </Link>
        <Link
          activeClassName="is-active"
          to={formatMessage({ id: 'routes.template' })}
          className="dashboard__sidebar__link"
          onClick={(event) =>
            handleLink(event, formatMessage({ id: 'routes.template' }))
          }
          data-cy="dashboard-sidebar-template"
        >
          <span>
            <FormattedMessage id="proposals.template.title" />
          </span>
        </Link>
        <Link
          activeClassName="is-active"
          to={formatMessage({ id: 'routes.archived' })}
          onClick={(event) =>
            handleLink(event, formatMessage({ id: 'routes.archived' }))
          }
          className="dashboard__sidebar__link"
          data-cy="dashboard-sidebar-archived"
        >
          <span>
            <FormattedMessage id="proposals.archived.title" />
          </span>
        </Link>
      </ul>
      {/* <ul>
        <Link
          activeClassName="is-active"
          to={formatMessage({ id: 'routes.proposal-statistics' })}
          onClick={(event) =>
            handleLink(
              event,
              formatMessage({ id: 'routes.proposal-statistics' }),
            )
          }
          className="dashboard__sidebar__link"
          data-cy="dashboard-sidebar-proposal-statistics"
        >
          <span>
            <FormattedMessage id="proposal-statistics.title" />
          </span>
        </Link>
      </ul> */}
    </nav>
  );
};
