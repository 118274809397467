import sparksIcon from 'assets/icons/icon-sparks-duo-48.svg';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'shared/components';
import { useSubmitOnEnter } from 'shared/hooks';
import { SubscriptionSelectModal } from './SubscriptionSelectModal';

export const VisibilitySelectorFreemiumModal: React.FC<ModalProps> = ({
  onClose,
}) => {
  const submitButtonRef = useSubmitOnEnter<HTMLButtonElement>();
  const [currentStep, setCurrentStep] = useState(1);

  const plusModal = {
    [1]: (
      <>
        <main className="f f--col f--align-center u-maxwidth--568__responsive">
          <img
            src={sparksIcon}
            alt="SparksIcon"
            className="u-width--48 u-height--48 f--shrink-zero"
          />
          <h1 className="t-center text--xl__responsive t-text-1 t-bold visibility-selector-freemium__modal__title">
            <FormattedMessage id="visibility-plus-modal.title" />
          </h1>
          <p className="t-text-2 text--xs__responsive u-maxwidth--432 t-center visibility-selector-freemium__modal__description">
            <FormattedMessage id="visibility-plus-modal.description" />
          </p>
        </main>
        <div className="f f--center">
          <Button
            size="lrg"
            style="secondary"
            ref={submitButtonRef}
            onClick={() => setCurrentStep(currentStep + 1)}
            btnSelector="proposal-expired-failed-confirm-btn"
            className="u--width--full--mobile"
          >
            <FormattedMessage id="buttons.upgrade-now" />
          </Button>
        </div>
      </>
    ),
    [2]: <SubscriptionSelectModal onClose={onClose} />,
  };

  return <>{plusModal[currentStep]}</>;
};
