import {
  GracePeriodNotice,
  PropozePlusNotice,
  StripePlansController,
} from 'modules/subscription';
import React, { PropsWithChildren } from 'react';
import {
  DashboardSidebarFooter,
  Header,
  Main,
  NavDropdown,
} from 'shared/components';
import { DashboardSidebar } from './fragments';

export const DashboardLayout: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      <div className="dashboard__container">
        <div className="dashboard__sidebar">
          <div className="dashboard__sidebar__content">
            <NavDropdown />
            <DashboardSidebar />
            <PropozePlusNotice className="free__limit__container" />
            <GracePeriodNotice />
          </div>
          <DashboardSidebarFooter
            isLeaveFeedbackVisible
            className="dashboard__sidebar__footer__sticky"
          />
        </div>
        <div className="dashboard__content">
          <Header />
          <Main>{children}</Main>
        </div>
      </div>
      <StripePlansController />
      <div id="global-modal" />
    </>
  );
};
