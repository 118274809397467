import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { subscriptionSelectors } from '../state';

export function useStripeProducts() {
  const products = useRecoilValue(subscriptionSelectors.productsSelector);

  const plusProducts = useMemo(
    () => products?.filter(({ name }) => name !== 'Free'),
    [products],
  );
  const freeProduct = useMemo(
    () => products?.find(({ name }) => name === 'Free'),
    [products],
  );
  const plusYearlyProduct = useMemo(
    () => plusProducts?.find(({ name }) => name === 'Yearly Plus'),
    [plusProducts],
  );
  const plusMonthlyProduct = useMemo(
    () => plusProducts?.find(({ name }) => name === 'Monthly Plus'),
    [plusProducts],
  );
  const productsLoading = useMemo(() => !products?.length, [products]);

  return {
    freeProduct,
    plusYearlyProduct,
    plusMonthlyProduct,
    productsLoading,
  };
}
