import React from 'react';
import { PublicFileSegmentList } from './fragments';

interface Props {
  item: FileSegment;
  index: number;
  style: Proposal['style'];
}

export const PublicFileSegmentCard: React.FC<Props> = ({
  item,
  index,
  style,
}) => {
  return (
    <>
      {item.files.length > 0 && (
        <div className="proposal-block-file">
          <PublicFileSegmentList item={item} index={index} style={style} />
        </div>
      )}
    </>
  );
};
