export function isFileMediaElementVisible(
  segment: FileSegment | MediaSegment,
  isPreview?: boolean,
) {
  if (!isPreview) return true;
  if (segment.files.length > 0) {
    return true;
  }

  return false;
}
