/**This function takes end date of subscription and adds grace period to it (15days)
 * Returns grace period formatted date
 */
export function getGracePeriodFormattedDate(end: number | null | undefined) {
  if (!end) return null;

  const gracePeriodEndDate = new Date(end + 15 * 24 * 60 * 60 * 1000);
  return new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  }).format(gracePeriodEndDate);
}
