import { useProposalOverviewFilterOptions } from 'modules/proposals-overview';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useKeyPress, useListNavigation, useMobile } from 'shared/hooks';
import { ProposalOverviewFilterOption } from '../ProposalOverviewFilterOption';

interface Props {
  proposalStatus: {
    labels: string[];
    statuses: PublishStatus[];
  };
}

export const ProposalOverviewProposalStatusOptions: React.FC<Props> = ({
  proposalStatus,
}) => {
  const isMobile = useMobile();
  const enterPress = useKeyPress('Enter');
  const { handleFilterChange } = useProposalOverviewFilterOptions();
  const { cursor, setHovered } = useListNavigation<string>(
    proposalStatus.labels,
  );

  /**Enter press */
  useEffect(() => {
    if (proposalStatus?.labels?.length && enterPress) {
      handleFilterChange('proposal-status', proposalStatus.statuses[cursor]);
    }
  }, [enterPress]);

  return (
    <ul className="proposal-overview__filter__options">
      {proposalStatus?.labels?.length ? (
        <>
          {proposalStatus.labels.map((label, index) => {
            const status = proposalStatus.statuses[index];
            return (
              <React.Fragment key={status}>
                <ProposalOverviewFilterOption
                  type="proposal-status"
                  name={status}
                  label={label}
                  isActive={index === cursor}
                  handleFilterChange={handleFilterChange}
                  onMouseEnter={() => setHovered(status)}
                  onMouseLeave={() => setHovered(undefined)}
                />
                {index !== proposalStatus.labels.length - 1 && isMobile && (
                  <div className="proposal-overview__actions__divider" />
                )}
              </React.Fragment>
            );
          })}
        </>
      ) : (
        <>
          <span className="proposal__overview__filter__option__error text--sm">
            <FormattedMessage id="proposals.overview.filter.no-results" />
          </span>
        </>
      )}
    </ul>
  );
};
