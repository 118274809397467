import { globalHistory } from '@reach/router';
import React, { createContext, useContext, useEffect, useState } from 'react';

interface HistoryContextType {
  history: string[];
  setHistory: React.Dispatch<React.SetStateAction<string[]>>;
}

const HistoryContext = createContext<HistoryContextType>({
  history: [],
  setHistory: () => {},
});

interface HistoryProviderProps {
  children: React.ReactNode;
}

export const HistoryProvider: React.FC<HistoryProviderProps> = ({
  children,
}) => {
  const [history, setHistory] = useState<string[]>([]);

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') {
        setHistory((currentHistory) => [
          ...currentHistory,
          window.location.pathname,
        ]);
      }
    });
  }, []);

  return (
    <HistoryContext.Provider value={{ history, setHistory }}>
      {children}
    </HistoryContext.Provider>
  );
};

export const useHistory = () => useContext(HistoryContext);
