import { useFunction } from 'modules/firebase';

export function useStripePricesCallables() {
  const getProducts = useFunction<undefined, StripeProductWithPrices[]>(
    'getProducts',
  );

  const callables = {
    getProducts,
  };

  const functionsInitialised = Object.values(callables).every((value) =>
    Boolean(value),
  );

  return {
    ...callables,
    functionsInitialised,
  };
}
