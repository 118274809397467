import { useFeatureFlag } from 'modules/feature-flags';
import React, { useEffect } from 'react';
import { useStripePrices } from '../hooks';

export const StripePlansController: React.FC = () => {
  const enabled = useFeatureFlag('SUBSCRIPTION');
  const { getStripeProducts, functionsInitialised } = useStripePrices();

  useEffect(() => {
    if (!functionsInitialised || !enabled) return;
    getStripeProducts();
  }, [functionsInitialised, enabled]);

  return <React.Fragment />;
};
