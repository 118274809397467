import expandIcon from 'assets/icons/icon-expand-more-16.svg';
import logoPropoze from 'assets/images/logo-xsmall-grey.svg';
import { AnimatePresence, motion } from 'framer-motion';
import { DashboardSidebar } from 'modules/dashboard';
import { GracePeriodNotice, PropozePlusNotice } from 'modules/subscription';
import React, { useState } from 'react';
import { LeaveFeedbackButton } from '../leave-feedback-button';

export const SlidingMenu: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  function toggleMenu(event: React.MouseEvent<HTMLElement>) {
    event.stopPropagation();
    const htmlElement = document.documentElement;
    if (!menuOpen) {
      htmlElement.classList.add('is-locked');
    } else {
      htmlElement.classList.remove('is-locked');
    }
    setMenuOpen(!menuOpen);
  }

  return (
    <div className="sliding-menu__container">
      <div className="f f--align-center u-width--32 u-height--32">
        <img
          data-cy="sliding-menu-btn"
          onClick={toggleMenu}
          src={expandIcon}
          className="u-width--16 u-height--16 f--shrink-zero"
          alt="expandIcon"
        />
      </div>

      <AnimatePresence>
        {menuOpen && (
          <>
            <motion.nav
              key="sliding-menu"
              initial="closed"
              animate={menuOpen ? 'open' : 'closed'}
              exit="closed"
              variants={{
                open: {
                  opacity: 1,
                  x: 0,
                  transition: { duration: 0.25, ease: 'easeOut' },
                },
                closed: {
                  opacity: 1,
                  x: '-100%',
                  transition: { duration: 0.25, ease: 'easeOut' },
                },
              }}
              className={`sliding-menu f f--col`}
            >
              <div className="f f--col">
                <DashboardSidebar onToggleMenu={toggleMenu} />
                <PropozePlusNotice className="free__limit__container" />
                <GracePeriodNotice />
              </div>

              <div className="f f--col">
                <div className="divider--verylight-5 u-width--full s-bottom--med" />
                <LeaveFeedbackButton className="u-width--full s-bottom--tny" />
                <div className="sliding-menu__footer f--justify-start s-top--lrg">
                  <img src={logoPropoze} alt="Propoze Logo" />
                  <span className="t-text-3 dashboard__sidebar__footer__version">
                    Version: {process.env.GATSBY_APP_VERSION?.split('-')[0]}
                  </span>
                </div>
              </div>
            </motion.nav>
            <motion.div
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
                transition: { duration: 0.25, delay: 0.25, ease: 'easeOut' },
              }}
              exit={{
                opacity: 0,
                transition: { duration: 0, delay: 0, ease: 'easeOut' },
              }}
              onClick={toggleMenu}
              className="sliding-menu__overlay"
            ></motion.div>
          </>
        )}
      </AnimatePresence>
    </div>
  );
};
