import { useScrollIntoView } from 'modules/authentication';
import { useRef, useState } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { CUSTOM_TAGS_RECENT_SEARCHES_LS_KEY } from 'shared/const';
import {
  useClickOutsideElement,
  useOnClickRecentSearchResults,
} from 'shared/hooks';
import { toast } from 'sonner';

const DEFAULT_CUSTOM_TAGS = ['development', 'design', 'qa'];

export function useSelectCustomTag(
  setValue: UseFormReturn<PublishStatusAware<Proposal>>['setValue'],
) {
  const { onFocus } = useScrollIntoView();
  const inputRef = useRef<HTMLInputElement>(null);

  const [inputValue, setInputValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [showResults, setShowResults] = useState(false);

  const customTags = useWatch({ name: 'customTags' });
  const { recentSearches, handleSetRecentSearch } =
    useOnClickRecentSearchResults(
      CUSTOM_TAGS_RECENT_SEARCHES_LS_KEY,
      DEFAULT_CUSTOM_TAGS,
    );

  function handleClickOutside() {
    setShowResults(false);
    inputRef.current?.blur();
  }
  const wrapperRef = useClickOutsideElement<HTMLDivElement>(
    handleClickOutside,
    true,
    true,
  );

  function removeTagAtIndex(index: number) {
    const newItems = [...customTags];
    newItems.splice(index, 1);
    setValue('customTags', newItems, { shouldDirty: true });
  }

  function handleFocus(event?: React.FocusEvent<HTMLInputElement, Element>) {
    setShowResults(true);
    setIsFocused(true);
    inputRef.current?.focus();

    if (event) {
      onFocus(event.currentTarget);
    }
  }

  function handleSearch(e: React.ChangeEvent<HTMLInputElement>) {
    setInputValue(e.target.value);
  }

  function handleAddCustomTag(tag: string) {
    const trimmedTag = tag.trim();
    if (trimmedTag === '') {
      toast.error('Tag cannot be just whitespace. Please add a valid tag.');
      return;
    }
    if (customTags?.includes(trimmedTag)) {
      toast.error('Tag already exists. Please add a new tag instead.');
      return;
    }
    if (trimmedTag.length > 20) {
      toast.error(`You can only add up to 20 characters.`);
      return;
    }
    setValue('customTags', [...customTags, trimmedTag], {
      shouldDirty: true,
    });
    handleSetRecentSearch(trimmedTag);
    setInputValue('');
    handleFocus();
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter' && inputValue) {
      e.preventDefault();
      handleAddCustomTag(inputValue);
    }

    if (e.key === 'Backspace' && !inputValue) {
      removeTagAtIndex(customTags?.length - 1);
    }
  }

  return {
    customTags,
    wrapperRef,
    inputValue,
    showResults,
    recentSearches,
    isFocused,
    inputRef,
    setShowResults,
    handleAddCustomTag,
    handleKeyDown,
    handleSearch,
    removeTagAtIndex,
    handleFocus,
    setIsFocused,
  };
}
