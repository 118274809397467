import { useFunction } from 'modules/firebase';

export function useStripeCustomerCallables() {
  const createCustomer = useFunction<
    CreateCustomerData,
    { customerId: string }
  >('createCustomer');

  const callables = {
    createCustomer,
  };

  const functionsInitialised = Object.values(callables).every((value) =>
    Boolean(value),
  );

  return {
    ...callables,
    functionsInitialised,
  };
}
