import {
  Settings,
  isOnboardingLastStepAtom,
  useConfigSelector,
  useSettings,
  useTaxActions,
} from 'modules/settings';
import { useFreeSubscription } from 'modules/subscription';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { toast } from 'sonner';

export function useOnboarding() {
  const config = useConfigSelector();
  const [currentStep, setCurrentStep] = useState(1);

  const { onOnboardFinish } = useTaxActions();
  const { createAsync, updateAsync } = useSettings();

  const [isOnboardingLastStep, setIsOnboardingLastStep] = useRecoilState(
    isOnboardingLastStepAtom,
  );
  const [isCreatingSubscription, setIsCreatingSubscription] = useState(false);
  const { isLoading, handleFreeSubscription } = useFreeSubscription();

  async function onOnboardingFinished(data: Settings) {
    try {
      setIsCreatingSubscription(true);
      setIsOnboardingLastStep(true);
      await handleFreeSubscription();
      await onOnboardFinish({
        ...data,
        isOnboardingInProgress: false,
      });
    } catch {
      toast.error(
        'Whoops, something went wrong when saving your data. Try again.',
      );
    } finally {
      setIsCreatingSubscription(false);
    }
  }

  async function handleCompanyInfoStep(data: Settings) {
    try {
      await updateAsync(data);
      setCurrentStep(currentStep + 1);
    } catch {
      toast.error(
        'Whoops, something went wrong when saving your data. Try again.',
      );
    }
  }

  async function handleReferralStep(data: Settings) {
    try {
      await updateAsync(data);
      setCurrentStep(currentStep + 1);
    } catch {
      toast.error(
        'Whoops, something went wrong when saving your data. Try again.',
      );
    }
  }

  async function handleAddressStep(data: Settings) {
    try {
      await updateAsync(data);
      setCurrentStep(currentStep + 1);
    } catch {
      toast.error(
        'Whoops, something went wrong when saving your data. Try again.',
      );
    }
  }

  useEffect(() => {
    if (config) return;
    createAsync({
      ...new Settings(),
      isOnboardingInProgress: true,
    });
  }, [config]);

  return {
    config,
    isLoading,
    currentStep,
    isOnboardingLastStep,
    isCreatingSubscription,
    setCurrentStep,
    handleAddressStep,
    handleCompanyInfoStep,
    handleReferralStep,
    onOnboardingFinished,
    setIsOnboardingLastStep,
  };
}
