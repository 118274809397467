import { authenticationSelector } from 'modules/authentication';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useRecoilValue } from 'recoil';
import { IntervalSelector, ProductFeatureList, ProductItemAction } from '.';
import { useStripeHelpers, useSubscriptionHelpers } from '../hooks';
import { subscriptionAtoms } from '../state';

interface Props {
  product: StripeProductWithPrices;
  selectedPrice?: StripePrice;
  isCheckoutLoading?: boolean;
  interval?: PaymentInterval;
  isPricingPage?: boolean;
  onSetInterval?: (interval: PaymentInterval) => void;
  onSelect?: (priceId: string) => void;
}

export const ProductInfo: React.FC<Props> = ({
  product,
  selectedPrice,
  isCheckoutLoading,
  interval,
  isPricingPage,
  onSetInterval,
  onSelect,
}) => {
  const {
    getProductFeatureListFromMetadata,
    getProductPriceStringByInterval,
    getPriceFromInterval,
  } = useStripeHelpers();
  const { name, id, metadata } = product;
  const price =
    selectedPrice || getPriceFromInterval(product.prices, interval || 'month');
  const { isCurrentPlan, isGracePeriod } = useSubscriptionHelpers();
  const isCurrent = isCurrentPlan({ priceId: price?.id, productId: id });
  const active = useRecoilValue(subscriptionAtoms.active);
  const { user } = useSelector(authenticationSelector.getState);

  if (!price) return null;

  const featureList = getProductFeatureListFromMetadata(metadata);
  const formattedPrice = getProductPriceStringByInterval(
    price,
    price.recurring?.interval,
  );

  const isActiveLabelShown = isCurrent && active && user && !isGracePeriod;
  const isGracePeriodLabelShown = isGracePeriod && active && user && isCurrent;

  return (
    <>
      <header className="f f--between">
        <h2 className="text--lg">
          {name !== 'Free' ? (
            <span
              className="subscription-settings-plan__name"
              data-cy="premium-plan-product-title"
            >
              <FormattedMessage id="current-plan-card.propoze-plus" />
            </span>
          ) : (
            <span data-cy="free-plan-product-title">{name}</span>
          )}
        </h2>

        {isActiveLabelShown && (
          <p
            className="subscription-settings-plan__status text--tny__responsive subscription-settings-plan__status--active"
            data-cy="plan-status-label"
          >
            <FormattedMessage id="current-plan-card.label.active" />
          </p>
        )}
        {isGracePeriodLabelShown && (
          <p
            className="subscription-settings-plan__status text--tny__responsive subscription-settings-plan__status--grace-period"
            data-cy="plan-status-label"
          >
            <FormattedMessage id="current-plan-card.label.grace-period" />
          </p>
        )}
      </header>
      {name !== 'Free' && onSetInterval && (
        <IntervalSelector interval={interval} setInterval={onSetInterval} />
      )}
      <main className="s-top--sml f--one">
        {isPricingPage && name !== 'Free' && (
          <h2 className="f f--align-center s-bottom--med">
            <strong>{formattedPrice.price}</strong>
          </h2>
        )}
        <ProductFeatureList
          features={featureList}
          productId={id}
          isVatNoteVisible={name !== 'Free'}
          className="list--bullets__bordered"
          featuresSelector={
            name !== 'Free'
              ? 'propoze-plus-features-list'
              : 'free-features-list'
          }
        />
        {name === 'Free' && (
          <span className="subscription-settings-free__notice t-text-2">
            <FormattedMessage id="subscription-select-modal.free-plan-list-notice-includes" />
            <strong className="t-bold text--xsm">
              <FormattedMessage id="subscription-select-modal.free-plan-list-notice-published-proposals" />
            </strong>
            <FormattedMessage id="subscription-select-modal.free-plan-list-notice-description" />
          </span>
        )}
      </main>
      {onSelect && (
        <footer className="f s-top--med">
          <ProductItemAction
            onSelect={() => onSelect(price.id)}
            priceId={price.id}
            productId={id}
            name={name}
            isCheckoutLoading={isCheckoutLoading}
          />
        </footer>
      )}
    </>
  );
};
