import infoIcon from 'assets/icons/icon-info-16.svg';
import { format } from 'date-fns';
import { FACELIFT_RELEASE_DATE } from 'modules/proposals';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Modal, ModalFooter } from 'shared/components';
import { useMobile, useModal } from 'shared/hooks';

export const PublishedProposalsLimitTooltip: React.FC = () => {
  const isMobile = useMobile();
  const { isModalOpen, openModal, closeModal } = useModal();

  function handleOpenModal() {
    if (!isMobile) return;
    openModal();
  }

  return (
    <>
      <div
        className="free__limit__tooltip__container"
        onClick={handleOpenModal}
      >
        <img
          src={infoIcon}
          alt="Info icon"
          className="visibility-selector-modal__icon"
        />
        <div className="free__limit__tooltip">
          <span className="text--xxs__responsive">
            <FormattedMessage
              id="propoze-plus-notice.tooltip.message1"
              values={{
                date: (
                  <strong>{format(FACELIFT_RELEASE_DATE, 'MMMM do')}</strong>
                ),
              }}
            />
            <FormattedMessage id="propoze-plus-notice.tooltip.message2" />
          </span>
          <div className="free__limit__tooltip__arrow" />
        </div>
      </div>

      {isModalOpen && (
        <Modal close={closeModal} isDashboardModal>
          <span className="text--xs s-bottom--lrg">
            <FormattedMessage
              id="propoze-plus-notice.tooltip.message1"
              values={{
                date: (
                  <strong>{format(FACELIFT_RELEASE_DATE, 'MMMM do')}</strong>
                ),
              }}
            />
            <FormattedMessage id="propoze-plus-notice.tooltip.message2" />
          </span>
          <ModalFooter>
            <Button
              onClick={closeModal}
              type="button"
              size="lrg"
              style="outline"
            >
              <FormattedMessage id="buttons.close" />
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};
